export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const CHECK_IS_AUTHENTICATED = 'CHECK_IS_AUTHENTICATED';
export const CHECK_IS_AUTHENTICATED_SUCCESS = 'CHECK_IS_AUTHENTICATED_SUCCESS';
export const CHECK_IS_AUTHENTICATED_FAILURE = 'CHECK_IS_AUTHENTICATED_FAILURE';

export interface LogInData {
  username: string;
  password: string;
}
