import { mapleResource } from 'core/api/mapleResource';
import { resource } from 'core/api/resource';


export async function getProducts<T>() {
  const url = `/api/developer/product/`;
  return resource.get<T>(url);
}

export async function getProductsMaple<T>() {
  const url = `/api/products`;
  // const url = `/api/admin/products`;
  return mapleResource.get<T>(url);
}

export async function deleteProduct<T>(id: string) {
  const url = `/api/developer/product/${id}`;
  return resource.delete<T>(url);
}

export function getAccountInfo<T>() {
  const url = "/api/account";
  return resource.get<T>(url);
}