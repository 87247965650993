import { Http, http } from '../api/http';
import { settings } from '../settings';


interface ProductInfo
{
  productId: string;
  versionNumber: string;
  enabled: boolean;
  isLastVersion: boolean;  
}

interface ModelInfo
{
  id?: number;
  code?: string;
  name?: string;
  isVirtual: boolean; 
  //features: 
}


interface DeviceInfo
{
  id: string;
  innerId: string;
  installedProducts: ProductInfo[];
  model: ModelInfo;
}

interface DeviceInfoList
{
  data: DeviceInfo[];  
}

export class DefaultUser extends Http {

  private token: string | null = null;
  private static devices: DeviceInfoList;

  constructor() {
    super(settings.baseURL);

    const storageToken = localStorage.getItem('moby.du.access_token');
    if(storageToken)
    {
      this.token = storageToken;
      this.request.interceptors.request.use(config => {
        const headers = Object.assign({}, config.headers, {
          authorization: `Bearer ${this.token}`,
          'api-version': settings['api-version'],
        });

        return {
          ...config,
          headers,
        };
      });
    }

    this.request.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            this.logout();
            console.log("default user unauthorised");
          }
          return Promise.reject(error.response);
        },
    ); 
  }

  private isAutorised() {
    return (this.token !== null);
  }

  private wearableId() : string
  {
    return DefaultUser.devices.data[0].id;
  }

  public async addVirtualDevice() : Promise<boolean>
  {
    try {
      const url = '/api/wearable/add_virtual';
      const rv = await defaultUser.post(url, 
        {
          "innerId": "default virtual device",
          "model": 
          {
            "deviceType": 3, 
            "brand": "Moby.ID",
            "model": "Default",
            "features": {"hasFingerprint": true},
          },
          "name": "default virtual device"
        }
      )
      // deviceType: 1 - iOS, 2 - Android, 3 - Moby


      const response = (rv.status === 200);
      return response;

    } catch (error) {
      
      return false;
    }
  }

  public async list() : Promise<boolean>
  {
    try {
      if(this.isAutorised())
      {
        const url = '/api/wearable';
        let rv = (await defaultUser.get<any>(url)) as DeviceInfoList;

        if(rv.data.length === 0) // no virtual device at defaut user yet
        {
          // add virtual device to default user 
          if(!(await defaultUser.addVirtualDevice()) as boolean)
            return false;
        }


        rv = (await defaultUser.get<any>(url)) as DeviceInfoList;
        if(rv.data.length > 0 && rv.data[0].model.isVirtual)
        {
          DefaultUser.devices = rv;
          return true;
        }                     
      }
    } catch (error) {
    }
    return false
  }

  private async buy(id: string) : Promise<boolean>
  {
    try {
      const url = `/api/customer/product/${id}/buy`;
      const rv = await defaultUser.post(url);
      
      const response = (rv.status === 400 || rv.status === 200)

      return response;
    } catch (error) {
      
      return false;
    }
  }

  private async link(id: string) : Promise<boolean>
  {
    try {
      if(this.wearableId === null)
        return false;
      
      const url = `/api/customer/product/${id}/link`;
      const rv = await defaultUser.post(url, {"wearableId": this.wearableId()});
      return (rv.status === 200)
        
    } catch (error) {
      return false;
    }
  }

  public async purchase(id: string) : Promise<boolean>
  {
    if(await this.buy(id))
    {
      return await this.link(id);
    }


    return false;
  }


  public async install(id: string, version: string) : Promise<boolean>
  {
    try {
      if(this.wearableId === null)
        return false;

      const url = `/api/script/wearable/${this.wearableId()}/installProduct`;
      const rv = await defaultUser.post(url, {"productId": id, "versionNumber": version});
      return (rv.status === 200 && (rv.data as any).closeSession);
        
    } catch (error) {
      return false;
    }
  }

  public async update(id: string, version: string) : Promise<boolean>
  {
    try {
      if(this.wearableId === null)
        return false;

      const url = `/api/script/wearable/${this.wearableId()}/updateProduct`;
      const rv = await defaultUser.post(url, {"productId": id, "versionNumber": version});
      return (rv.status === 200 && (rv.data as any).closeSession);
        
    } catch (error) {
      return false;
    }
  }

  public async uninstall(id: string) : Promise<boolean>
  {
    try {
      if(this.wearableId === null)
        return false;

      const url = `/api/script/wearable/${this.wearableId()}/uninstallProduct`;
      const rv = await defaultUser.post(url, {"productId": id});
      return (rv.status === 200 && (rv.data as any).closeSession);
        
    } catch (error) {
      return false;
    }
  }

  public logout()
  {
    this.token = null;
    localStorage.removeItem('moby.du.access_token');
  }


  public async login() : Promise<boolean>
  {
    try {
      if(!this.isAutorised())
      {                
        const url = '/api/auth/login/default'
        const {
          data: { accessToken, tokenType },
        } = await http.post<any>(url);

        if (tokenType === 'Bearer') {

          localStorage.setItem('moby.du.access_token', accessToken);
          // localStorage.setItem('moby.du.refresh_token', refreshToken);
          this.token = accessToken;

          this.request.interceptors.request.use(config => {
            const headers = Object.assign({}, config.headers, {
              authorization: `Bearer ${accessToken}`,
              'api-version': settings['api-version'],
            });

            return {
              ...config,
              headers,
            };
          });
        }
      }

      return true;

    } catch (error) {

      return false;
    }
  }
}

export const defaultUser = new DefaultUser();
