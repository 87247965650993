import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, notification, Collapse, Space } from 'antd';
// import { TokenInfo } from './types';

// import { getAccountInfo } from './resource/resource';
// import jwt_decode from 'jwt-decode';

import {Passwords, userAccount, UserDetails, UserFullDetails} from 'core/api/account';
import {providerAccount, ProviderInfo} from 'core/api/provider';

// import UserList from './components/UsersList';
// import ProviderList from './components/ProviderList';

import { EditOutlined } from '@ant-design/icons';
import UpdateUserModal from './components/UpdateUserModal'
import UserPasswordModal from './components/UserPasswordModal'
import UpdateProviderModal from './components/UpdateProviderModal'
import moment from 'moment';



const AccountDetails: React.FC = function () {
  const { Title } = Typography;

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [user, setUser] = useState<UserFullDetails>({provider:{id: "", name: "", isDefault: false}});
  const [provider, setProvider] = useState<ProviderInfo>();

  const [userInfo, setUserInfo] = useState<UserDetails>({firstName: "", lastName: ""});

  // const [account, setAccount] = useState<TokenInfo>({family_name: '', given_name: ''});

  const { Panel } = Collapse;

  const [userInfoModal, setUserInfoModal] = React.useState(false);


  const [userPasswordsModal, setUserPasswordsModal] = React.useState(false);
  const [updateProviderModal, setUpdateProviderModal] = useState<boolean>(false);

  
  async function getUserInfo() {
    try {
      console.log('init1');
      const rv = await userAccount.getInfo<UserFullDetails>();
      setUser(rv.data);

    } catch (e) {
      notification.error({
        message: e.toString(),
      });
    }
  }

  async function getProviderInfo() {
    try {
      
      const rv = await providerAccount.getInfo();
      setProvider(rv);

    } catch (e) {
      notification.error({
        message: e.toString(),
      });
    }
  }

  useEffect(() => {
    // async function loadMaplePermissions() {
    //   try {      
        
    //     const key = localStorage.getItem('apiKey') as string;
    //     if(key !== undefined && key.length > 0)
    //     {
    //       setIsLoad(true);
    //       const r = await getAccountInfo<{ roles: string[] }>();
    //       setList(r.data.roles);
    //       setIsLoad(false);
    //     }
    //   } catch (e) {
    //     localStorage.removeItem('apiKey');
    //     notification.error({
    //       message: e.toString(),
    //     });
    //     setIsLoad(false);
    //   }
    // }

    // function loadCedarPermissions()
    // {
    //   try {      
    //     const token = localStorage.getItem('accessToken');
    //     const data = jwt_decode(token as string) as TokenInfo;
    //     setAccount(data);
    //   } catch (e) {
    //     notification.error({
    //       message: e.toString(),
    //     });
    //   }
    // }  

    getUserInfo();
    getProviderInfo();

    // loadCedarPermissions();
    // loadMaplePermissions();
  }, [])

    const handleUserInfoOk = async () => {
      setIsLoad(true);

      let invlalidate = false;
      console.log(userInfo.firstName + ' ' + userInfo.lastName);
      let firstName = '';
      let lastName = '';

      if(userInfo.firstName.length > 0)
      {
        invlalidate = true;
        firstName = userInfo.firstName;
      } 
      else
      {
        firstName = user.firstName !== undefined ? user.firstName : ''; 
      } 

      if(userInfo.lastName.length > 0)
      {
        invlalidate = true;
        lastName = userInfo.lastName;
      } 
      else
      {
        lastName = user.lastName !== undefined ? user.lastName : '';
      } 
      
      if(invlalidate)
      {
        try {
          console.log('init2');
          await userAccount.updateInfo({firstName: firstName, lastName: lastName});        
          const rv = await userAccount.getInfo<UserFullDetails>();
          setUser(rv.data);          
        } catch (e) {          
          notification.error({
            message: e.toString(),
          });          
        }
      }
  
      setIsLoad(false);
      setUserInfoModal(false);
    };

    function callback(key: any) {
      // console.log(key);
    }
    
    const providerEdit = () => (
      <EditOutlined
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          setUpdateProviderModal(true);
        }}
      />
    );

    const userEdit = () => (
      <EditOutlined
        onClick={event => {
          event.stopPropagation();
          setUserInfoModal(true);
        }}
      />
    );


  return (
    <>
      <Title level={4}>Account details</Title>
      <Collapse
          defaultActiveKey={['1','2']}
          onChange={callback}
          expandIconPosition='left'
        >
          <Panel header="User details" key="1" extra={userEdit()}>
            <Row>
              <Col span={18}>
                <Space align="baseline">
                  <p><b>User: </b>{user?.lastName} {user?.firstName}</p>     
                </Space>
                <p><b>E-mail: </b>{user?.email}</p>
                <p><b>Phone: </b>{user?.phoneNumber}</p>
              </Col>

              <Col span={6}>
                <UserPasswordModal
                  isModalVisible={userPasswordsModal}
                  handleCancel = {() => setUserPasswordsModal(false)}
                  handleOk = {() => setUserPasswordsModal(false)}
                >
                </UserPasswordModal>


                <UpdateUserModal 
                  confirmLoading={isLoad}
                  isModalVisible={userInfoModal}
                  handleCancel = {() => setUserInfoModal(false)}
                  handleOk = {handleUserInfoOk}
                  info = {user}
                  setDataFormUpdate = {setUserInfo}
                >              
                </UpdateUserModal>
                
                <Space direction='vertical'>
                  {/* <Button type={'primary'} onClick={() => {setUserInfoModal(true)}}>
                    Edit details
                  </Button> */}
                  <Button type={'primary'} onClick={() => {setUserPasswordsModal(true)}}>
                    Change password
                  </Button>
                </Space>
              </Col>
            </Row>


          </Panel>
          
          <Panel header="Provider details" key="2" extra={providerEdit()}>
            <p><b>Provider {provider?.isDefault ? '( default provider )' : ''}: </b>{provider?.translations[0].name}</p>
            <p><b>Email: </b>{provider?.email}</p>
            <p><b>Phone: </b>{provider?.phoneNumber || '--'}</p>
            <p><b>Web: </b>{provider?.site}</p>
            <p><b>Form-factors: </b>{provider?.mobyApiKey ? 'enabled' : 'disabled'}</p>
            <p><b>Created: </b>{moment(provider?.created).format('ll')}</p>

            <UpdateProviderModal
              isModalVisible={updateProviderModal}
              handleCancel = {() => setUpdateProviderModal(false)}
              handleOk = {() => { 
                setUpdateProviderModal(false);
                getProviderInfo();
              }}
              info = {provider}
            ></UpdateProviderModal>
          </Panel>

          {/* <Panel header="Users" key="3">
          <UserList></UserList>
          </Panel>

          <Panel header="Providers" key="4">
          <ProviderList></ProviderList>
          </Panel> */}
      </Collapse>

      {/* <Title level={5}>Account permissions:</Title>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={account.role}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <br />

      <Title level={5}>Maple service permissions:</Title>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={list}
        renderItem={(item) => <List.Item>{item.replace(/_/gi, ' ')}</List.Item>} // change _ to blank symbol
      /> */}
    </>
  );
};

export default AccountDetails;
