import { resource } from 'core/api/resource';


export enum UserRoles {
    Developer,
    MapleDeveloper, // has api-key
    Admin,
    Owner
  }

export interface ProviderTranslation
{
    localeCode: string; // Locale code, en-US etc
    name: string; // Provider name
}

export interface UserRole
{
    id: number;   
    name: string;
}

export interface ProviderUserInfo
{
    id: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    roles: UserRole[];
    enabled: boolean;
    created: string;
    isCurrent?:boolean;
}

export interface ProviderInfo
{
    id: string;
    email?: string;
    site?: string;
    phoneNumber?: string;
    mobyApiKey?: string;
    isDefault: boolean;
    created: string; // Creation date (UTC)
    translations: ProviderTranslation[];
    users: ProviderUserInfo[];
}

export interface ProviderUpdateInfo
{
    email?: string;
    site?: string;
    phoneNumber?: string;
    mobyApiKey?: string;
    translations: ProviderTranslation[];
}

export interface AddUserInfo
{
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roleIds: number[];
}

export interface LocaleInfo
{
    code: string;
    name: string;
}

export interface AccountLocale
{
    totalCount: number;
    list: LocaleInfo[];
}


export class ProviderAccount  {

    private static userRoles : UserRoles[] = [];

    private initRoles(data: ProviderInfo)
    {
        if(ProviderAccount.userRoles.length === 0)
        {
            // init account status
            // const {data} = await this.getInfo<ProviderInfo>();
            const rv = data.users.find((e) => { return e.isCurrent });
            
            console.log(rv?.email);
            rv?.roles.map((e) => {console.log("\ts-role: " + e.id + ":" + e.name)});

            rv?.roles.map((role) => {
                switch(role.id)
                {
                    case 1: break;// consumer 
                    case 2: // developer
                    {
                        ProviderAccount.userRoles.push(UserRoles.Developer);
                        if(data.mobyApiKey !== undefined)
                        {
                            ProviderAccount.userRoles.push(UserRoles.MapleDeveloper);
                        }
                    } break;
                    case 3: // owner of platform (Admin via cloud enum)
                        ProviderAccount.userRoles.push(UserRoles.Owner);
                        ProviderAccount.userRoles.push(UserRoles.Admin); // owner has provider's rights too
                    break;
                    case 4: // provider
                        ProviderAccount.userRoles.push(UserRoles.Admin);
                    break;
                }
            })
        }
    }

    public checkRole(role: UserRoles) : boolean
    {
        const result = ProviderAccount.userRoles.includes(role);

        return (result !== undefined);;
    }

    public isAnyRoleIncluded(roles: UserRoles[]) : boolean
    {    
        const rv = ProviderAccount.userRoles.find((role) => {
            return roles.includes(role);
        })

        return (rv !== undefined);
    }
    
    private async getProviderInfo<T>()
    {
        const url = `/api/provider`;
        return resource.get<T>(url);
    }

    public async getInfo() : Promise<ProviderInfo>
    {
        const { data } = await this.getProviderInfo<ProviderInfo>();

        this.initRoles(data);

        return data;
    }

    public async updateInfo(params: ProviderUpdateInfo) : Promise<boolean>
    {
        const url = `/api/provider`;
        const rv = await resource.put(url, params);

        return (rv.status === 200);
    }

    public async getUserRoles<T>()
    {
        const url = `/api/provider/user/roles`;
        return resource.get<T>(url);
    }


    public async addUser<T>(info: AddUserInfo)
    {
        const url = `/api/provider/user`;
        return resource.post<T>(url, info);
    }

    public async deleteUser<T>(id: string)
    {
        const url = `/api/provider/user/${id}`;
        return resource.delete<T>(url);
    }

    public async getLocale<T>()
    {
        const url = `/api/developer/locale`;
        return resource.get<T>(url);
    }
}

export const providerAccount = new ProviderAccount();