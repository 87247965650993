import React, { useEffect, useState } from 'react';
import { Space, Card, Image, Button, Row, Col, Input, notification, Badge, Dropdown, Menu, Tooltip, Typography } from 'antd';
import block from 'bem-cn';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import * as T from './types';
import { deleteProduct, getProducts, getProductsMaple, getAccountInfo } from './resource/resource';
import { saveDataForCreateProduct } from 'core/state/product/actions';
import ModalCreateMiniApp from './components/ModalCreateMiniApp/ModalCreateMiniApp';

import './productList.scss';
import { EllipsisOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';


const productList = block('productList');

const ProductList: React.FC = function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Title } = Typography;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isNoOneBootApp, setIsNoOneBootApp] = useState<boolean>(true);
  const [isDefaultAdmin, setIsDefaultAdmin] = useState<boolean>(true);
  const [products, setProducts] = useState<T.ItemProduct[]>([]);
  const [productsMaple, setProductsMaple] = useState<T.ProductMaple[]>([]);

  const [dataFormCreateProduct, setDataFormCreateProduct] = useState<T.ProductForm>({
    isDefault: false,
    isBoot: false,
    isVirtual: true,
    renewAvailable: false,
    serviceProductId: '',
  });

  useEffect(() => {
    async function getProduct() {
      try {
        
        const acc = await getAccountInfo<T.AccountDetails>();
        
        const {
          data: { list },
        } = await getProducts<{ list: T.ItemProduct[] }>();
        

          if(acc.data.provider.isDefault)
          {
            list.map((e) => {
              if(e.info.isVirtual)
              {
                if(e.info.virtualFeatures.isBoot)
                {
                  setIsNoOneBootApp(false);
                  return;
                }
              }
            })
          }
          setIsDefaultAdmin(acc.data.provider.isDefault);

        setProducts(list);
      } catch (e) {
        notification.error({
          message: e.toString(),
        });
      }
    }

    async function getProductMaple() {
      try {
        const { data } = await getProductsMaple<T.ProductMaple[]>();
        const productsMaple = data.filter((e) => !e.versions[0].parent_product);
        setProductsMaple(productsMaple);
        setDataFormCreateProduct((data) => {
          return { ...data, serviceProductId: productsMaple[0].id };
        });
      } catch (e) {
        notification.error({
          message: e.toString(),
        });
      }
    }


    getProduct();
    localStorage.getItem('apiKey') && getProductMaple();
    dispatch(saveDataForCreateProduct({ productCreate: null }));

  }, []);

  const [textSearch, setTextSearch] = useState<string>('');

  const handleChange = (value: string) => {
    setTextSearch(value);
  };

  let list = products;
  if (textSearch.length > 0) {
    list = products.filter((e) => {
      return e.info.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  const onClickDetails = (id: string) => {
    history.push(`/product/${id}`);
  };

  const onDelete = async (id: string) => {
    try {
        await deleteProduct<{ info: T.Product }>(id as string);
        notification.info({
          message: 'Deleted',
        });

        const {
          data: { list },
        } = await getProducts<{ list: T.ItemProduct[] }>();
        setProducts(list);

  } 
    catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }


  };

  const menu = (id: T.ItemProduct) => (
    <Menu>
      <Menu.Item danger={!id.info.enabled} disabled={id.info.enabled} icon={<DeleteTwoTone twoToneColor="#eb2f96" />} onClick={() => onDelete(id.info.id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const onNextCreteProduct = () => {
    dispatch(
      saveDataForCreateProduct({
        productCreate: {
          renewAvailable: dataFormCreateProduct.renewAvailable,
          translations: [],
          serviceProductId: !dataFormCreateProduct.isVirtual ? dataFormCreateProduct.serviceProductId : null,
          productTypeIds: [],
          tags: [],
          maxInstallationsCount: 1,
          virtualFeatures: dataFormCreateProduct.isVirtual ?
          {
            isBoot: dataFormCreateProduct.isBoot,
            isDefault: dataFormCreateProduct.isDefault,
            menuIndex: null,
            sidebarIndex: null,
          } : null,
          wearableFeatures: {
            hasFingerprint: false,
            hasGeneralCommands: false,
            hasKeys: false,
            hasSystemCommands: false,
            hasJsCommands: false,
            hasSeCommands: false,
          },
        },
      }),
    );
    history.push(`/create_mini_app`);
  };

  return (
    <div className={productList()}>
      <ModalCreateMiniApp
        productsMaple={productsMaple}
        isModalVisible={isModalVisible}
        onOk={onNextCreteProduct}
        handleCancel={() => setIsModalVisible(false)}
        dataFormCreateProduct={dataFormCreateProduct}
        setDataFormCreateProduct={setDataFormCreateProduct}
        isNoBootYet={isDefaultAdmin && isNoOneBootApp}
        isDefaultAvailable={isDefaultAdmin}
      />
      <Row justify={'space-between'}>
        <Col span={6}>
          <Title level={4}>Miniapps</Title>
        </Col>
        <Col span={18}>
          <Input.Search
            placeholder="Find miniapp by name"
            onSearch={handleChange}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Col>
      </Row>
      <Button type={'primary'} onClick={() => setIsModalVisible(true)}>
            Add miniapp
      </Button>
      <br />
      <br />

      <div className="site-card-wrapper">
        <Space size='large' /* split={<Divider type="vertical"  style={{ height: '100px', width: '1px', background: 'orange' }}} */ wrap>          
          {new Array(list.length).fill(null).map((_, index) => (      
            // eslint-disable-next-line react/no-array-index-key
            <Badge.Ribbon text={list[index].info.enabled ? 'published' : 'unpublished'} 
                          color={list[index].info.enabled ? 'green' : 'grey'} key={index}>
            

            <Card
                      bodyStyle={{ width: '260px' }}
                      style={{'borderRadius':'5px'}}
                      actions={[

                        <EditTwoTone key="edit" onClick={() => onClickDetails(list[index].info.id)}/>, 
                        <Dropdown overlay={menu(list[index])} placement="topCenter">
                        <EllipsisOutlined key="ellipsis"/>
                       </Dropdown>,
                      ]}
                      cover={
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <Image
                            style={{ margin: '5px' }}
                            height={162}
                            width={240}
                            preview={false}
                            src={list[index].info?.icon?.url || 'no images'}
                          />
                        </div>
                      }
                    >
                      <Card.Meta
                        // style={{ height: '150px' }}
                        title={<Tooltip title={list[index].info.name}>
                        <p>{list[index].info.name}</p>
                      </Tooltip>}
                        // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                        description={
                          <>
                            <p><b>category:</b> {list[index].info.productTypes[0].name}</p>
                            <p><b>type:</b> {list[index].info.isVirtual ? (list[index].info.virtualFeatures.isDefault ? (list[index].info.virtualFeatures.isBoot ? 'boot' : 'default' ) : 'virtual') : 'form-factor'}</p>
                          </>
                        }
                      />
                    </Card>
                    </Badge.Ribbon>

          ))}
        </Space>
      </div>
    </div>
  );
};

export default ProductList;
