import { resource } from 'core/api/resource';

export interface Passwords
{
    currentPassword: string;
    newPassword: string;
}

export interface UserDetails
{
    firstName: string;
    lastName: string;
}

export interface ProviderDetails
{
    name: string;
    email?: string;
    site?: string;
    id: string;
    isDefault: boolean;
}

export interface UserFullDetails
{
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    provider: ProviderDetails;
}


export class UserAccount  {

    public async getInfo<T>()
    {
        console.log('userAccount::getInfo()');
        const url = `/api/account`;
        return resource.get<T>(url);
    }
    
    public async updateInfo(params: UserDetails) : Promise<boolean>
    {
        const url = `/api/account`;
        const rv = await resource.put(url, params);

        return (rv.status === 200);
    }

    public async changePassword(params: Passwords) : Promise<boolean>
    {
        const url = `/api/account/password`;
        const rv = await resource.put(url, params);

        return (rv.status === 200);
    }

}

export const userAccount = new UserAccount();