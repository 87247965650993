import React, { Dispatch, SetStateAction } from 'react';
import { Form, Input, Modal } from 'antd';
import { UserDetails, UserFullDetails } from 'core/api/account';


interface Props {
  setDataFormUpdate: Dispatch<SetStateAction<UserDetails>>;
  info: UserFullDetails | undefined;

  confirmLoading: boolean;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const UpdateUserModal: React.FC<Props> = function ({
  setDataFormUpdate,
  info,
  
  confirmLoading,
  isModalVisible,
  handleOk,
  handleCancel
}) {

  const onChangeFirstName= (firstName: string) => {
    setDataFormUpdate((data) => {
      console.log(firstName);
      return { ...data, firstName };
    });
  };

  const onChangeLastName= (lastName: string) => {
    setDataFormUpdate((data) => {
      console.log(lastName);
      return { ...data, lastName };
    });
  };

  return (
    <Modal
    title="User details"
    visible={isModalVisible}
    onOk={handleOk}
    confirmLoading={confirmLoading}
    onCancel={handleCancel}
  >
    <Form>
      <Form.Item label="First name" name={'firstName'}>
        <Input placeholder="Type your first name" defaultValue={info?.firstName} onChange={(e) => onChangeFirstName(e.target.value)}/>
      </Form.Item>
      <Form.Item label="Last name" name={'lastName'}>
        <Input placeholder="Type your last name" defaultValue={info?.lastName} onChange={(e) => onChangeLastName(e.target.value)}/>
      </Form.Item>
    </Form>
  </Modal>
  );
};

export default UpdateUserModal;
