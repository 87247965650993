import * as T_USERS from './types';

export function fetchLogIn(payload: T_USERS.LogInData) {
  return {
    type: T_USERS.FETCH_LOGIN,
    payload,
  };
}

export function checkIsAuth() {
  return {
    type: T_USERS.CHECK_IS_AUTHENTICATED,
  };
}
