import React, { useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { getTemplates, getScriptHandlers, getBinaryImages, postTemplate, deleteTemplate } from './resource/resource';
import * as T from './types';
import ModalAddTemplate from './components/ModalAddTemplate/ModalAddTemplate';

const Templates: React.FC = function () {
  const { Title } = Typography;

  const [templates, setTemplates] = useState<T.Template[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [handlers, setHandlers] = useState<T.Handlers[]>([]);
  const [binaryImages, setBinaryImages] = useState<T.BinaryImages[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<T.Template | undefined>(undefined);

  async function getData(variableId?: string) {
    try {
      setIsLoading(true);
      const { data } = await getTemplates<T.Template[]>();
      setTemplates(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    async function getHandlers() {
      try {
        const { data } = await getScriptHandlers<T.Handlers[]>();
        setHandlers(data);
      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.statusText ? e.statusText.toString() : e.toString(),
        });
      }
    }

    async function getBinaryImage() {
      try {
        const { data } = await getBinaryImages<T.BinaryImages[]>();
        setBinaryImages(data);
      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.statusText ? e.statusText.toString() : e.toString(),
        });
      }
    }

    getBinaryImage();
    getHandlers();
    getData();
  }, []);

  const onPostTemplate = async (data: T.DataPostTemplate) => {
    try {
      await postTemplate(data);
      getData();
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const onDelete = async (id: string) => {
    try {
      await deleteTemplate(id);
      getData();
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const onSelectedTempalte = (template: T.Template) => {
    setSelectedTemplate(template);
    setIsShowModal(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (t: string) => {
        return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: T.Template, i: number) => {
        return (
          <Space>
            <Button
              style={{width:'80px', textAlign:'center'}}
              type={'primary'}
              onClick={() => {
                onSelectedTempalte(data);
              }}
            >
              Edit
            </Button>
            <Button danger style={{width:'80px', textAlign:'center'}} onClick={() => onDelete(data.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = templates;
  if (textSearch.length > 0) {
    list = templates.filter((e) => {
      return e.id.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  return (
    <>
      <ModalAddTemplate
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        handlers={handlers}
        binaryImages={binaryImages}
        onPostTemplate={onPostTemplate}
      />
      <Row>
        <Col span={6}>
          <Title level={4}>Templates</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search by variable id" onSearch={onSearch} />
        </Col>
      </Row>
      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Template
      </Button>
      <br />
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => {
            let variables = [];

            if (record.variables) {
              for (const data in record.variables) {
                variables.push(
                  <p>
                    <b>{data}</b> : {record.variables[data]}
                  </p>,
                );
              }
            }

            return (
              <Row>
                <Col span={6}>
                  <>
                    <p>
                      <b>required_variable_ids</b>
                    </p>
                    {record.required_variable_ids
                      ? record.required_variable_ids.map((e, i) => {
                          return <p key={i}>{e}</p>;
                        })
                      : '-'}
                  </>
                </Col>
                <Col span={6}>
                  <>
                    <p>
                      <b>optional_variable_ids</b>
                    </p>

                    {record.optional_variable_ids
                      ? record.optional_variable_ids.map((e, i) => {
                          return <p key={i}>{e}</p>;
                        })
                      : '-'}
                  </>
                </Col>
                <Col span={12}>
                  <>
                    <p>
                      <b>variables</b>
                    </p>
                    {!!variables.length ? variables : '-'}
                  </>
                </Col>
              </Row>
            );
          },
        }}
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default Templates;
