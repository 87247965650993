import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal, Form, Button } from 'antd';

import Dropzone from 'shared/components/Dropzone/Dropzone';

interface Props {
  isShowModal: boolean;
  onAddJerryScript(formData: FormData): void;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
}

const ModalAddBinary: React.FC<Props> = React.memo(({ isShowModal, onAddJerryScript, setIsShowModal }) => {
  const [file, setFile] = useState<FormData>();

  const onHandlerSuccess = () => {
    file && onAddJerryScript(file);
    onClose();
  };

  const onClose = () => {
    setFile(undefined);
    setIsShowModal(false);
  };

  return (
    <Modal
      title={'Add Device Handler package'}
      visible={isShowModal}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        <Button key="submit" type="primary" disabled={!file} onClick={onHandlerSuccess}>
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Binary Image file" name="img">
          <Dropzone setFileIcon={setFile} fileIcon={file} nameInput={'zip'} />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ModalAddBinary;
