import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, notification, Space } from 'antd';
import { providerAccount, ProviderInfo, ProviderTranslation } from 'core/api/provider';


interface Props {
  info: ProviderInfo | undefined;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const UpdateProviderModal: React.FC<Props> = function ({
  info,
  isModalVisible,
  handleOk,
  handleCancel
}) {


  const getData = async() => 
  {
    console.log('getData');
    setEmail(info?.email || '');
    setSite(info?.site || '');
    setPhone(info?.phoneNumber || '');
    setApiKey(info?.mobyApiKey || '');
    setTranslations([{localeCode: 'en-US', name: getName('en-US')}]);
  }

  useEffect(
    () => {
      getData();
    },
    [isModalVisible],
  )

  const getName = (code: string) =>
  {    
    const rv = info?.translations.find(
      (e) => {
       if(e.localeCode === code){          
          return e;              
        }
      });

    return rv?.name || '';
  }

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [translations, setTranslations] = useState<ProviderTranslation[]>([{localeCode: 'en-US', name: getName('en-US')}]);
  const [email, setEmail] = useState<string>(info?.email || '');
  const [site, setSite] = useState<string>(info?.site || '');
  const [phone, setPhone] = useState<string>(info?.phoneNumber || '');
  const [apiKey, setApiKey] = useState<string>(info?.mobyApiKey || '');


  const onSave = async() => {
      try {
        setConfirmLoading(true);
        const rv = await providerAccount.updateInfo({email: email, site: site, phoneNumber: phone, mobyApiKey: apiKey, translations: translations});
        setConfirmLoading(false);

        handleOk();

      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
        });
        setConfirmLoading(false);
      }

  }

  const renderTranslations = () => {
    try {
      return (
        <Space align='end' direction='vertical'>
          {
            translations.map((t, i) => {    
              return  <Form.Item label={"Name (" + t.localeCode + ")"}>
                        <Input style={{width: '330px'}}
                            defaultValue = {info?.translations[i].name || ''}
                            placeholder={"Type " + t.localeCode +  " provider name"} 
                            onChange={(e) => 
                            {
                              let rv = translations;
                              rv[i].name = e.target.value;
                              setTranslations(rv);
                            }}/>
                      </Form.Item>
            })
          }
        </Space>
      )

    } catch (error) {
      
    }
  }


  return (
    <Modal
    title="Update provider details"
    visible={isModalVisible}
    onOk={onSave}
    confirmLoading={confirmLoading}
    onCancel={() => {
      handleCancel()
    }}
  >
    <Form>
      <Space align='end' direction='vertical'>
        <Form.Item label="E-mail">
          <Input style={{width: '330px'}} placeholder="Type provider e-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </Form.Item>    
        <Form.Item label="Web">
          <Input style={{width: '330px'}} placeholder="Type provider site" value={site} onChange={(e) => setSite(e.target.value)}/>
        </Form.Item>    
        <Form.Item label="Phone">
          <Input style={{width: '330px'}} placeholder="Type provider phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
        </Form.Item>  
        <Form.Item label="Api-key">
          <Input style={{width: '330px'}} placeholder="Type Maple api-key" value={apiKey} onChange={(e) => setApiKey(e.target.value)}/>
        </Form.Item>      
        {renderTranslations()}
      </Space>
    </Form>
  </Modal>
  );
};

export default UpdateProviderModal;
