import React, { useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { getScripts, deleteScripts, postScript, getScriptHandler, postScriptHandlers } from './resource/resource';
import * as T from './types';
import ModalAddScript from './components/ModalAddScript/ModalAddScript';
import ModalUpdateEmbedded from './components/ModalUpdateEmbedded/ModalUpdateEmbedded';

const CloudHandlers: React.FC = function () {
  const { Title } = Typography;

  const [scripts, setScripts] = useState<T.Script[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [editableData, setEditableData] = useState<{ id: string; js: string } | undefined>(undefined);
  const [textSearch, setTextSearch] = useState<string>('');

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [embbeddedData, setEmbbeddedData] = useState<T.Script[]>([]);//useState<any | undefined>(undefined);

  async function getData(variableId?: string) {
    try {
      setIsLoading(true);
      const { data } = await getScripts<T.Script[]>(variableId);
      setScripts(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const onDelete = async (id: string) => {
    try {
      await deleteScripts(id);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const onUpdateScript = async () => {
    try {
      const { data } = await postScriptHandlers<T.Script[]>();
      if(data.length > 0)
      {
        setEmbbeddedData(data);
        setIsModalVisible(true);
      }
      else
      {
        notification.success({
          message: 'Update embedded handlers',
          description: 'No new records. All done',
        }); 
      }
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const onSetEditableData = async (id: string) => {
    try {
      const { data } = await getScriptHandler<string>(id);
      setEditableData({ id, js: data });
      setIsShowModal(true);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const onSuccess = async (id: string, js: string) => {
    try {
      await postScript(id, js);
      setEditableData(undefined);
      setIsShowModal(false);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const onSearchVariable = (value: string) => {
    getData(value);
  };

  const onSearchById = (value: string) => {
    setTextSearch(value);
  };


  let list = scripts;
  if (textSearch.length > 0) {
    list = scripts.filter((e) => {
      return e.id.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  const columns = [
    {
      title: (
        <Space>
          ID:
          <Input.Search
            allowClear
            placeholder="Find handlers by name"
            onSearch={onSearchById}
            onChange={(e) => onSearchById(e.target.value)}
          />
        </Space>
      ),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (t: string, scritp: T.Script) => {
        return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: T.Script, i: number) => {
        return (
          <Space>
            {data.type !== 'embedded' ? (
              data.type === 'embedded_javascript' ? (
                <Button style={{width:'80px', textAlign:'center'}} type={'primary'} onClick={() => onUpdateScript()}>
                  Update
                </Button>
              ) : (
                <Button style={{width:'80px', textAlign:'center'}} type={'primary'} onClick={() => onSetEditableData(data.id)}>
                  Edit
                </Button>
              )
            ) : (
              ''
            )}
            {data.type === 'javascript' && (
              <Button danger style={{width:'80px', textAlign:'center'}} onClick={() => onDelete(data.id)}>
                Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalAddScript
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onSuccess={onSuccess}
        editableData={editableData}
        setEditableData={setEditableData}
      />
      <ModalUpdateEmbedded
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        embeddedData={embbeddedData}
      />
      <Row>
        <Col span={6}>
          <Title level={4}>Cloud handlers</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="Find all handlers with variable id, for example CARD_AID" onSearch={onSearchVariable} />
        </Col>
      </Row>
      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Cloud Handler
      </Button>
      <br />
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Row>
                <Col span={6}>
                  <>
                    <p>
                      <b>required_variable_ids</b>
                    </p>
                    {record.required_variable_ids
                      ? record.required_variable_ids.map((e, i) => {
                          return <p key={i}>{e}</p>;
                        })
                      : '-'}
                  </>
                </Col>
                <Col span={18}>
                  <>
                    <p>
                      <b>optional_variable_ids</b>
                    </p>

                    {record.optional_variable_ids
                      ? record.optional_variable_ids.map((e, i) => {
                          return <p key={i}>{e}</p>;
                        })
                      : '-'}
                  </>
                </Col>
              </Row>
            );
          },
        }}
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default CloudHandlers;
