import { IAction } from '../../types';
import * as T from './types';

export interface StateProduct {
  productType: T.ProductType[];
  locale: T.Locale[];
  productCreate: T.ProductCreate | null;
}
const initialState: StateProduct = {
  productType: [],
  locale: [],
  productCreate: null,
};

export function productReducer(state = initialState, { type, payload }: IAction<StateProduct>) {
  switch (type) {
    case T.FETCH_GET_PRODUCT_TYPE_SUCCESS: {
      return { ...state, productType: payload.productType };
    }
    case T.FETCH_GET_LOCATE_SUCCESS: {
      return { ...state, locale: payload.locale };
    }

    case T.SAVE_DATA_FOR_CREATE_PRODUCT: {
      return { ...state, productCreate: payload.productCreate };
    }

    default:
      return state;
  }
}
