import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import Dropzone from '../../../../shared/components/Dropzone/Dropzone';

interface Props {
  isShowModal: boolean;
  onAddBinaryImg(name: string, formData: FormData): void;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
}

const ModalAddBinary: React.FC<Props> = React.memo(({ isShowModal, onAddBinaryImg, setIsShowModal }) => {
  const [name, setName] = useState<string>('');
  const [file, setFile] = useState<FormData>();

  const onHandlerSuccess = () => {
    file && onAddBinaryImg(name, file);
    onClose();
  };

  const onClose = () => {
    setName('');
    setFile(undefined);
    setIsShowModal(false);
  };

  return (
    <Modal
      title={'Add Binary Image'}
      visible={isShowModal}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        <Button key="submit" type="primary" disabled={!name.length || !file} onClick={onHandlerSuccess}>
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item initialValue={name} label="Name" required>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Binary Image file" name="img">
          <Dropzone setFileIcon={setFile} fileIcon={file} nameInput={'image'} />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ModalAddBinary;
