import { resource } from 'core/api/resource';
import {ProviderTranslation} from './provider';

export interface AdminProviderInfo
{
    id: string;
    name: string;
    email?: string;
    site?: string;
    phoneNumber?: string;
    hasMobyApiKey?: boolean;
    isDefault: boolean;
    enabled: boolean;
    usersCount: number;
    created: string; // Creation date (UTC)
}

export interface AddProviderInfo
{
    email: string;
    translations: ProviderTranslation[];
}

export interface AddAdminInfo
{
    email: string;
    password: string;
}


export class AdminAccount  {

    public async getInfo<T>()
    {
        const url = `/api/admin/provider`;
        return resource.get<T>(url);
    }

    public async updateInfo(id: string, enable: boolean) : Promise<boolean>
    {
        const url = `/api/admin/provider/${id}`;
        const rv = await resource.put(url, {enabled: enable});

        return (rv.status === 200);
    }

    public async addProvider<T>(info: AddProviderInfo)
    {
        const url = `/api/admin/provider`;
        return resource.post<T>(url, info);
    }

    public async addAdmin<T>(id: string, info: AddAdminInfo)
    {
        const url = `/api/admin/provider/${id}/admin`;
        return resource.post<T>(url, info);
    }
}

export const adminAccount = new AdminAccount();