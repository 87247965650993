import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Modal, Table, Input, Space } from 'antd';
import { Script } from 'modules/cloudHandlers/types';
import moment from 'moment';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  embeddedData: Script[];

}
const ModalUpdateEmbedded: React.FC<Props> = function ({
  isModalVisible,
  setIsModalVisible,
  embeddedData,
}) {


    const [textSearch, setTextSearch] = useState<string>('');

  const onClose = () => {
    setIsModalVisible(false);
  };


  const onSearchById = (value: string) => {
    setTextSearch(value);
  };


  let list = embeddedData;
  if (textSearch.length > 0) {
    list = embeddedData.filter((e) => {
      return e.id.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  const columns = [
    {
        title: (
            <Space>
              ID:
              <Input.Search
                allowClear
                placeholder="input search text"
                onSearch={onSearchById}
                onChange={(e) => onSearchById(e.target.value)}
              />
            </Space>
          ),
      dataIndex: 'id',
      key: 'id',
    },
    // {
    //   title: 'Data',
    //   dataIndex: 'created_time',
    //   key: 'created_time',
    //   render: (t: string) => {
    //     return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
    //   },
    // },
  ];

  return (
    <Modal
      title="List of updated embedded handlers"
      visible={isModalVisible}
      width={500}
      style={{ top: 20 }}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Table
        dataSource={list}
        columns={columns}
        rowKey={'id'}
      />
    </Modal>
  );
};

export default ModalUpdateEmbedded;
