import { resource } from 'core/api/resource';
import { ProductCreate } from 'core/state/product/types';

export function postAddProduct<T>(data: ProductCreate) {
  const url = `/api/developer/product/`;
  return resource.post<T>(url, data);
}

export async function loadIcon<T>(formData: FormData, id: string) {
  const url = `/api/storage/icon`;
  formData.append('productId', id);
  return resource.post<T>(url, formData);
}
