import React, { useState } from 'react';
import { Form, Input, Modal, notification, Space } from 'antd';
import { adminAccount } from 'core/api/admin';
import { LocaleInfo, ProviderTranslation } from 'core/api/provider';


interface Props {
  locale: LocaleInfo[];
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const AddProviderModal: React.FC<Props> = function ({
  locale,
  isModalVisible,
  handleOk,
  handleCancel
}) {

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [translations, setTranslations] = useState<ProviderTranslation[]>([{localeCode: 'en-US', name: ''}]);
  const [email, setEmail] = useState<string>('');

  const onSave = async() => {
      try {
        setConfirmLoading(true);
        await adminAccount.addProvider({translations: translations, email: email});
        setConfirmLoading(false);

        cleanForm();

        handleOk();

      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
        });
        setConfirmLoading(false);
      }

  };

  const cleanForm = () => {
    setEmail('');
    setTranslations([]);
  }

  const renderTranslations = () => {
    try {
      return (
        <Space align='end' direction='vertical'>
          {
            translations.map((t, i) => {    
              return  <Form.Item label={"Name (" + t.localeCode + ")"}>
                        <Input style={{width: '330px'}}
                            placeholder={"Type " + t.localeCode +  " provider name"} 
                            onChange={(e) => 
                            {
                              let rv = translations;
                              rv[i].name = e.target.value;
                              setTranslations(rv);
                            }}/>
                      </Form.Item>
            })
          }
        </Space>
      )

    } catch (error) {
      
    }
  }


  return (
    <Modal
    title="Add provider"
    visible={isModalVisible}
    onOk={onSave}
    confirmLoading={confirmLoading}
    onCancel={() => {
      cleanForm(); 
      handleCancel()
    }}
  >
    <Form>
      <Space align='end' direction='vertical'>
        {/* <Form.Item label="Name">
          <Input style={{width: '330px'}} placeholder="Type provider name" value={name} onChange={(e) => setName(e.target.value)}/>
        </Form.Item> */}
        <Form.Item label="E-mail">
          <Input style={{width: '330px'}} placeholder="Type provider e-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </Form.Item>      
        {renderTranslations()}
      </Space>
    </Form>
  </Modal>
  );
};

export default AddProviderModal;
