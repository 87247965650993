import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CoreReducer } from 'core/types';

import Language from './components/Language/Language';
import { saveDataForCreateProduct } from 'core/state/product/actions';
import { ProductCreate } from 'core/state/product/types';
import { Button, Col, Form, Input, InputNumber, notification, Row, Select } from 'antd';
import Dropzone from 'shared/components/Dropzone/Dropzone';
import { postAddProduct, loadIcon } from './resource/resource';

const CreateMiniApp: React.FC = function () {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fileIcon, setFileIcon] = useState<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const productCreateData = useSelector((state: CoreReducer) => state.coreReducer.productReducer.productCreate);

  useEffect(() => {
    if (productCreateData === null) {
      history.push(`/dashboard/miniapps`);
    }
  }, [productCreateData]);

  useEffect(() => {
    if (productCreateData) {
      form.setFieldsValue({
        maxInstallationsCount: productCreateData.maxInstallationsCount,
      });
    }
  }, [productCreateData]);

  const updateProductCreateData = (name: string, data: any) => {
    dispatch(
      saveDataForCreateProduct({
        productCreate: {
          ...(productCreateData as ProductCreate),
          [name]: data,
        },
      }),
    );
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      if (!fileIcon) {
        throw 'Need to load icon';
      }
      if (productCreateData) {
        const {
          data: { id },
        } = await postAddProduct<{ id: string }>(productCreateData);

        await loadIcon<{ id: string }>(fileIcon, id);
        setIsLoading(false);
        history.push(`/product/${id}`);
        dispatch(
          saveDataForCreateProduct({
            productCreate: null,
          }),
        );
      }
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  if (productCreateData) {
    return (
      <>
        <Language translations={productCreateData.translations} updateProductCrateData={updateProductCreateData} />
        <br />
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>

            <Col className="gutter-row">
              <Form.Item label="Icon" name="icon"
                // rules={[{ required: true, message: 'Please add icon' }]}
              >
                <Dropzone setFileIcon={setFileIcon} fileIcon={fileIcon} imageType={true}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Product category"
                name="productTypeIds"
                rules={[{ required: true, message: 'Please choose a product category' }]}
              >
                <Select onSelect={(e) => updateProductCreateData('productTypeIds', [+e])}>
                  <option value="1">Travel</option>
                  <option value="2">Access</option>
                  <option value="1002">Loyalty</option>
                  <option value="1003">Dev</option>
                  <option value="1004">Payment</option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{'visibility': 'hidden'}} className="gutter-row" span={6}>
              <Form.Item                
                label="Install limit"
                name="maxInstallationsCount"
                rules={[{ required: true, message: 'Install limit!' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  prefix="$"
                  placeholder="Install limit"
                  onChange={(e) => {
                    updateProductCreateData('maxInstallationsCount', e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </>
    );
  }
  return <></>;
};

export default CreateMiniApp;
