import { Http } from './http';
import { settings } from '../settings';

export class MapleResource extends Http {
  public constructor() {
    super(settings.baseURLMaple);

    this.request.interceptors.request.use((config) => {
      const headers = Object.assign({}, config.headers, {
        Authorization: `ApiKey ${localStorage.getItem('apiKey')}`,
        'api-version': settings.apiVersionMaple,
      });

      return {
        ...config,
        headers,
      };
    });
  }

}

export const mapleResource = new MapleResource();
