import React, { useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { getJerryScript, postJerryScript, deleteJerryScript, getJerryScriptArchive } from './resource/resource';
import * as T from './types';
import ModalAddBinary from './components/ModalAddBinary/ModalAddBinary';

const DeviceHandlers: React.FC = function () {
  const { Title } = Typography;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [data, setData] = useState<T.JerryScript[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');

  async function getData() {
    try {
      setIsLoading(true);
      const { data } = await getJerryScript<T.JerryScript[]>();
      console.log('data', data);
      setData(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const onAddJerryScript = async (formData: FormData) => {
    try {
      await postJerryScript(formData);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const onDelete = async (id: string) => {
    try {
      await deleteJerryScript(id);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const str2bytes = (str: string) => {
    var bytes = new Uint8Array(str.length);
    for (var i=0; i<str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  const onGetArchive = async (id: string, name: string) => {
    try {
      const d = await getJerryScriptArchive<string>(id);

      let a = document.createElement('a');
      document.body.appendChild(a);
      let blob = new Blob([str2bytes(d.data)], { type: 'application/zip' }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${name}.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (t: string) => {
        return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: T.JerryScript, i: number) => {
        return (
          <Space>
            <Button style={{width:'100px', textAlign:'center'}}type="primary" onClick={() => onGetArchive(data.id, data.name)}>
              Download
            </Button>
            <Button danger style={{width:'80px', textAlign:'center'}} onClick={() => onDelete(data.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = data;
  if (textSearch.length > 0) {
    list = data.filter((e) => {
      return e.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  return (
    <>
      <ModalAddBinary isShowModal={isShowModal} setIsShowModal={setIsShowModal} onAddJerryScript={onAddJerryScript} />

      <Row>
        <Col span={6}>
          <Title level={4}>Device Handlers</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search" onSearch={onSearch} />
        </Col>
      </Row>
      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Device Handler
      </Button>
      <br />
      <br />
      <Table
        expandable={{
          expandedRowRender: (d) => {
            return (
              <>
                <p>
                  <b>relations:</b>
                </p>
                <ul>
                  <li>backup-wrapper : {d.relations['backup-wrapper'] ? d.relations['backup-wrapper'] : '-'}</li>
                  <li>led-wrapper : {d.relations['led-wrapper'] ? d.relations['led-wrapper'] : '-'}</li>
                  <li>memory-wrapper : {d.relations['memory-wrapper'] ? d.relations['memory-wrapper'] : '-'}</li>
                  <li>security-wrapper : {d.relations['security-wrapper'] ? d.relations['security-wrapper'] : '-'}</li>
                  <li>se-wrapper : {d.relations['se-wrapper'] ? d.relations['se-wrapper'] : '-'}</li>
                </ul>
                <p>
                  <b>number:</b> {d.number}
                </p>
              </>
            );
          },
        }}
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default DeviceHandlers;
