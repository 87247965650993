import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import Cleave from 'cleave.js/react';

import * as T from '../../types';
import Dropzone from 'shared/components/Dropzone/Dropzone';

interface Props {
  isShowModal: boolean;
  productMaple: T.ProductMaple[];
  product: T.ProductCreate;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  addVersion(data: { number: string; serviceApiVersionNumber: string }, fileIcon: FormData): void;
}

const ModalAddVersion: React.FC<Props> = function ({ isShowModal, setIsShowModal, product, productMaple, addVersion }) {
  const [form] = Form.useForm();
  const [serviceName, setServiceName] = useState<string>('');
  const [versions, setVersions] = useState<{ [key: string]: any }>([]);
  const [fileIcon, setFileIcon] = useState<FormData>();
  const [data, setData] = useState<{
    number: string;
    serviceApiVersionNumber: string;
  }>({
    number: '',
    serviceApiVersionNumber: '',
  });

  const onClose = () => {
    setIsShowModal(false);
    setData({
      number: '',
      serviceApiVersionNumber: '',
    });
    setFileIcon(undefined);
  };

  useEffect(() => {
    if (product.serviceProductId != null ) 
    {
      const r = productMaple.find((e) => e.id === product.serviceProductId);
      if (r) {
        setServiceName("'" + r.name + "' version:");
        setVersions(
          r.versions.map((e) => {
            return {
              key: e.version,
              title: e.version,
            };
          }),
        );
      }
      else
      {
        setIsShowModal(false);
      }
    } 
    else
    {
      setVersions([]);
    }
  }, [productMaple, product]);

  const save = () => {
    fileIcon && addVersion(data, fileIcon);
    onClose();
  };

  return (
    <Modal
      title="Add version"
      visible={isShowModal}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!fileIcon || !data.number.length || (!!versions.length && !data.serviceApiVersionNumber.length)}
          onClick={save}
        >
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Miniapp version:" name="number">
          <Cleave
            className="ant-input"
            placeholder="Input a miniapp version"
            options={{
              delimiter: '.',
              blocks: [1, 1, 1],
            }}
            onChange={(event) => {
              const value = event.target.value;
              setData((d) => {
                return { ...d, number: value };
              });
            }}
          />
        </Form.Item>
        {
          !!versions.length && (
          <Form.Item label={serviceName} name="serviceApiVersionNumber">
            <Select
              placeholder="Select a version"
              onChange={(v) => {
                setData((d) => {
                  return { ...d, serviceApiVersionNumber: v as string };
                });
              }}
            >
              {versions.map((e: { [key: string]: any }) => {
                return (
                  <Select.Option value={e.key} key={e.key}>
                    {e.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Miniapp zip archive:" name="archive">
          <Dropzone setFileIcon={setFileIcon} fileIcon={fileIcon}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddVersion;
