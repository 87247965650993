import React, { useEffect } from 'react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { block } from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, notification } from 'antd';

import logo from './assets/logo.png';
import { checkIsAuth, fetchLogIn } from 'core/state/user/actions';
import { CoreReducer } from 'core/types';

import { settings } from '../../core/settings';

import './Login.scss';
// import { EnvironmentFilled } from '@ant-design/icons';
const login = block('login');

const Login: React.FC = function () {
  const history = useHistory();
  const dispatch = useDispatch();

  const errorTextLogIn = useSelector((state: CoreReducer) => state.coreReducer.userReducer.errorTextLogIn);
  const isLoadingLogIn = useSelector((state: CoreReducer) => state.coreReducer.userReducer.isLoadingLogIn);
  const isAuthenticated = useSelector((state: CoreReducer) => state.coreReducer.userReducer.isAuthenticated);

  useEffect(() => {
    dispatch(checkIsAuth());
  });

  useEffect(() => {
    if (isAuthenticated) {
      // window.location.href = '/dashboard/documentation';
      // window.location.href = '/dashboard/miniapps';
      
      window.location.href = '/dashboard/cedar/account'; // TODO
      // history.push(`/dashboard/cedar/account`);
      
    }
  }, [isAuthenticated]);

  useEffect(() => {
    !!errorTextLogIn.length && notification.error({
      message: 'Error',
      description: errorTextLogIn,
    });
  }, [errorTextLogIn]);

  useEffect(() => {
    if(settings.baseURL.length == 0){
      notification.error({
        message: 'Error',
        description: "CEDAR API URL not settled in .env file",
      });
    }

    if(settings.baseURLMaple.length == 0){
      notification.error({
        message: 'Error',
        description: "MAPLE API URL not settled in .env file",
      });
    }
  });

  const onFinish = (data: { username: string; password: string }) => {
    dispatch(fetchLogIn({ username: data.username, password: data.password }));
  };

  return (
    <div className={login()}>

      <div className={login('form')}>
        <img src={logo} alt="Logo" className={login('logo')} />

        <Form onFinish={onFinish}>
          <Form.Item
            label={<FaUserAlt />}
            name="username"
            rules={[{ required: true, message: 'Please choose a username.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<FaLock />}
            name="password"
            rules={[{ required: true, message: 'Please provide a password.' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoadingLogIn} disabled={settings.baseURL.length == 0 || settings.baseURL.length == 0}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;