import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { providerAccount, ProviderInfo, ProviderUserInfo, UserRole } from 'core/api/provider';
import AddUserModal from './components/AddUserModal'


const UsersList: React.FC = function () {
  const { Title } = Typography;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [data, setData] = useState<ProviderUserInfo[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');

  async function getData() {
    try {
      setIsLoading(true);
      const data = await providerAccount.getInfo();      
      setData(data.users);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  
  useEffect(() => {
      getData();
  }, []);

  const onDelete = async (id: string) => {
    try {
      await providerAccount.deleteUser(id);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const isDeveloper = (roles: UserRole[]) : boolean => {
    return (roles.find(e => e.id === 2) !== undefined);
  }

  const isAdmin = (roles: UserRole[]) : boolean => {
    return (roles.find(e => e.id === 3) !== undefined);
  }

  const isProvider = (roles: UserRole[]) : boolean => {
    return (roles.find(e => e.id === 4) !== undefined);
  }


  const columns = [
    {
      title: 'Login',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
        title: 'Developer',
        dataIndex: 'roles',
        key: 'roles',
        render: (t: UserRole[]) => {
            return <Checkbox disabled checked={isDeveloper(t)}></Checkbox>;
        },
    },
    {
      title: ' Admin',
      dataIndex: 'roles',
      key: 'roles',
      render: (t: UserRole[]) => {
          return <Checkbox disabled checked={isProvider(t) || isAdmin(t)}></Checkbox>;
      },
    },
    {
        title: 'Owner',
        dataIndex: 'roles',
        key: 'roles',
        render: (t: UserRole[]) => {
            return <Checkbox disabled checked={isAdmin(t)}></Checkbox>;
        },
    },    
    {
      title: 'Created date',
      dataIndex: 'created',
      key: 'created',
      render: (t: string) => {
        return <span>{moment(t).format('ll')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: ProviderUserInfo) => {
        return (
          <Space>
            {/* <Button
              type={'primary'}
              style={{width:'80px', textAlign:'center'}}
              onClick={() => {
                  console.log(data.id);
              }}
            >
              {data.enabled ? "Disable" : "Enable"}
            </Button> */}
            <Button danger disabled={isAdmin(data.roles) || data.isCurrent} style={{width:'80px', textAlign:'center'}}onClick={() => onDelete(data.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = data;
  if (textSearch.length > 0) {
    list = data.filter((e) => {
      return e.email.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  const handleUserAddOk = () => {
    setIsShowModal(false);
    getData();
  }

  return (
    <>
      <AddUserModal isModalVisible={isShowModal} handleCancel={() => setIsShowModal(false)} handleOk={() => handleUserAddOk()}/>
      <Title level={4}>Account users</Title>
      <Row>
        <Col span={6}>
          <Title level={4}>Find by email:</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search" onSearch={onSearch} />
        </Col>
      </Row>

      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add User
      </Button>
      <br />
      <br />
      <Table
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default UsersList;

