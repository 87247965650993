import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Typography, Button, Col, Form, InputNumber, notification, Row, Select, Space, Spin, Switch, Table } from 'antd';
import moment from 'moment';

import {
  getProductVersions,
  getProduct,
  getProductsMaple,
  putProduct,
  loadIcon,
  loadFile,
  postProductVersions,
  putProductVersions,
} from './resource/resource';
import * as T from './types';
import ModalAddVersion from './components/ModalAddVersion/ModalAddVersion';
import Language from './components/Language/Language';
import Dropzone from 'shared/components/Dropzone/Dropzone';
import {defaultUser} from '../../core/api/defaultUser';

const { Title } = Typography;

const Product: React.FC = () => {
  let { id } = useParams();
  const [form] = Form.useForm();

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [product, setProduct] = useState<T.ProductCreate | undefined>(undefined);
  const [isVirtual, setIsVirtual] = useState<boolean>(true);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fileIcon, setFileIcon] = useState<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productMaple, setProductMaple] = useState<T.ProductMaple[]>([]);
  const [idProduct, setIdProduct] = useState<string>('');
  const [listVersions, setListVersions] = useState<T.VersionItem[]>([]);
  const [isLoadingAddVersion, setIsLoadingAddVersion] = useState<boolean>(false);
  const [isLoadingChangeEnable, setIsLoadingChangeEnable] = useState<boolean>(false);
  const [icon, setIcon] = useState<string>('');

  useEffect(() => {
    try {
      const getDataProductMaple = async () => {
        try {
          const { data } = await getProductsMaple<T.ProductMaple[]>();
          const productsMaple = data.filter((e) => !e.versions[0].parent_product);
          setProductMaple(productsMaple);
        } catch (e) {
          notification.error({
            message: 'Error',
            description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
          });
        }
      };

      const getDataProduct = async () => {
        const {
          data: { list },
        } = await getProductVersions<{ list: T.VersionItem[] }>(id as string);

        setListVersions(list);

        const product = await getProduct<{ info: T.Product }>(id as string);
        if(product.data.info.icon !== undefined)
          setIcon(product.data.info.icon.url);

        setIdProduct(product.data.info.id);
        setIsVirtual(product.data.info.isVirtual);
        if(product.data.info.isVirtual)
          setIsDefault(product.data.info.virtualFeatures.isDefault);


        if(!product.data.info.isVirtual)
          localStorage.getItem('apiKey') && getDataProductMaple();

        setProduct({
          enabled: product.data.info.enabled,
          translations: product.data.info.translations,
          productTypeIds: product.data.info.productTypes.map((e) => e.id),
          maxInstallationsCount: product.data.info.maxInstallationsCount > 1 ? product.data.info.maxInstallationsCount : 1,
          tags: product.data.info.tags,
          serviceProductId: product.data.info.serviceProductId,
          renewAvailable: product.data.info.renewAvailable,
          virtualFeatures: product.data.info.isVirtual ? {
            isBoot: product.data.info.virtualFeatures.isBoot,
            isDefault: product.data.info.virtualFeatures.isDefault,
            menuIndex: product.data.info.virtualFeatures.menuIndex,
            sidebarIndex: product.data.info.virtualFeatures.sidebarIndex,
          } : null,
          wearableFeatures: {
            hasGeneralCommands: product.data.info.wearableFeatures.hasGeneralCommands,
            hasKeys: product.data.info.wearableFeatures.hasKeys,
            hasSystemCommands: product.data.info.wearableFeatures.hasSystemCommands,
            hasJsCommands: product.data.info.wearableFeatures.hasJsCommands,    
            hasSeCommands: product.data.info.wearableFeatures.hasSeCommands,
            hasFingerprint: product.data.info.wearableFeatures.hasFingerprint,
          },
        });


        !list.length && setIsShowModal(true);

      };

      const initDefaulUser = async () => {
        await defaultUser.login();
        await defaultUser.list();
      }

      getDataProduct();

      initDefaulUser();

    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
      });
    }
  }, [id]);

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        maxInstallationsCount: product.maxInstallationsCount,
        productTypeIds: product.productTypeIds[0].toString(),
        tags: product.tags,
        menuIndex: (product.virtualFeatures !== null) ? product.virtualFeatures.menuIndex : null,
        sidebarIndex: (product.virtualFeatures !== null) ? product.virtualFeatures.sidebarIndex : null,
      });
    }
  }, [product, form]);

  const updateProduct = (name: string, data: any) => {
    const dataNew = product ? { ...product, [name]: data } : undefined;
    setProduct(dataNew);
  };

  const updateVirtualFeatures = (name: string, data: any) => {
    const dataNew = product ? product : undefined;
    let features = (dataNew as T.ProductCreate).virtualFeatures;

    if(features !== null)
    {
      if(name === 'menuIndex')
        features.menuIndex = data;
      else
        features.sidebarIndex = data;
    }

    setProduct(dataNew);
  };

  const addVersion = async (data: { number: string; serviceApiVersionNumber: string }, fileIcon: FormData) => {
    try {
      setIsLoadingAddVersion(false);
      const newVersion: T.VersionCreate = {
        productId: idProduct,
        number: data.number,
        enabled: false,
        serviceApiVersionNumber: data.serviceApiVersionNumber.length ? data.serviceApiVersionNumber : null,
        translations: product?.translations || [],
      };
      const {
        data: { id },
      } = await postProductVersions<{ id: string }>(newVersion);

      await loadFile(fileIcon, id);
      const {
        data: { list },
      } = await getProductVersions<{ list: T.VersionItem[] }>(idProduct);

      setListVersions(list);

      setIsLoadingAddVersion(false);
    } catch (e) {
      setIsLoadingAddVersion(false);
      notification.error({
        message: 'Error',
        description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
      });
    }
  };

  const onChangeEnable = async (data: T.VersionItem) => {
    try {
      setIsLoadingChangeEnable(true);
      const d = { enabled: !data.info.enabled, translations: data.info.translations };
      await putProductVersions(data.info.id, d);
      setIsLoadingChangeEnable(false);

      setIsLoadingAddVersion(true);
      const {
        data: { list },
      } = await getProductVersions<{ list: T.VersionItem[] }>(idProduct);
      setListVersions(list);

      setIsLoadingAddVersion(false);


      // TODO: check to auto-update after version enable changing
      const product = await getProduct<{ info: T.Product }>(id as string);
      
      if(product.data.info.virtualFeatures.isDefault)
      {
        if(product.data.info.enabled || list.length === 1)
        {
          console.log("product is enabled");
          if(list.length === 1)
          {
            if(list[0].installedOnCount > 0)
            {
              // update
              console.log("update: " + product.data.info.id + ' : v' + list[0].info.number);
              await defaultUser.update(product.data.info.id, list[0].info.number);
            }
            else
            {
              // install
              console.log("install: " + product.data.info.id + ' : v' + list[0].info.number);
              await defaultUser.purchase(product.data.info.id);
              await defaultUser.install(product.data.info.id, list[0].info.number);
            }
          }
          else
          {
            const version = list.find((e) => {return e.info.enabled});
            if(version) // update
            {
              console.log("update (change): " + product.data.info.id + ' : v' + version.info.number);
              await defaultUser.update(product.data.info.id, version.info.number);
            }
          }
        }
        else
        {
          // defaultUser.uninstall(product.data.info.id);
          console.log("product is disabled");
        }
      }

      
      setProduct({
        enabled: product.data.info.enabled,
        translations: product.data.info.translations,
        productTypeIds: product.data.info.productTypes.map((e) => e.id),
        maxInstallationsCount: product.data.info.maxInstallationsCount > 1 ? product.data.info.maxInstallationsCount : 1,
        tags: product.data.info.tags,
        serviceProductId: product.data.info.serviceProductId,
        renewAvailable: product.data.info.renewAvailable,
        virtualFeatures: {
          isBoot: product.data.info.virtualFeatures.isBoot,
          isDefault: product.data.info.virtualFeatures.isDefault,
          menuIndex: product.data.info.virtualFeatures.menuIndex,
          sidebarIndex: product.data.info.virtualFeatures.sidebarIndex,
        },
        wearableFeatures: {
          hasGeneralCommands: product.data.info.wearableFeatures.hasGeneralCommands,
          hasKeys: product.data.info.wearableFeatures.hasKeys,
          hasSystemCommands: product.data.info.wearableFeatures.hasSystemCommands,
          hasJsCommands: product.data.info.wearableFeatures.hasJsCommands,    
          hasSeCommands: product.data.info.wearableFeatures.hasSeCommands,
          hasFingerprint: product.data.info.wearableFeatures.hasFingerprint,
        },
      });
      //

    } catch (e) {
      setIsLoadingChangeEnable(false);
      notification.error({
        message: 'Error',
        description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
      });
    }
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      if (product) {
        if (fileIcon) {
          await loadIcon<{ id: string }>(fileIcon, idProduct);
        }
        await putProduct(idProduct, product);
        setIsLoading(false);
        notification.success({
          message: 'Save',
        });
      }
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
      });
    }
  };

  const columns = [
    {
      title: 'Version',
      dataIndex: 'info.number',
      key: 'info.number',
      render: (t: string, data: T.VersionItem) => {
        return <span>{data.info.number}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'info.created',
      key: 'info.created',
      render: (t: string, data: T.VersionItem) => {
        return <span>{moment(data.info.created).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Installed',
      dataIndex: 'installedOnCount',
      key: 'installedOnCount',
    },
    {
      title: 'Publish',
      key: 'action',
      render: (text: string, data: T.VersionItem, i: number) => {
        return (
          <Space size="middle">
            <Switch
              checked={data.info.enabled}
              onChange={() => onChangeEnable(data)}
              loading={isLoadingChangeEnable}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      
      {!product ? (
        
        <Spin />
      ) : (
        
        <>
          <Title level={4} type={product.enabled ? "success" : "warning"}>{product.enabled ? 'Published miniapp' : 'Unpublished miniapp'}</Title>
          <ModalAddVersion
            product={product}
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
            productMaple={productMaple}
            addVersion={addVersion}
          />
          <br />
          <Language translations={product.translations} updateProduct={updateProduct} />
          <br />
          <Form layout="vertical" form={form} onFinish={onFinish}>
            
          <Row gutter={32}>
            <Col className="gutter-row">
              <Form.Item label="Icon" name="icon">
                <Dropzone setFileIcon={setFileIcon} fileIcon={fileIcon} fileUrl={icon} imageType={true}/>
              </Form.Item>
            </Col>

                    
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Product category"
                name="productTypeIds"
                rules={[{ required: true, message: 'Product category!' }]}
              >
                <Select onSelect={(e) => updateProduct('productTypeIds', [+e])}>
                  <option value="1">Travel</option>
                  <option value="2">Access</option>
                  <option value="1002">Loyalty</option>
                  <option value="1003">Dev</option>
                  <option value="1004">Payment</option>
                </Select>
              </Form.Item>
            </Col>

            <Col style={{'visibility': isVirtual ? 'hidden' : 'visible'}}  className="gutter-row" span={6}>
              <Form.Item
                label="Install limit"                  
                name="maxInstallationsCount"
                rules={[{ required: true, message: 'Install limit!' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  prefix="$"
                  placeholder="Install limit"
                  min={1} max={10}
                  onChange={(e) => {
                    updateProduct('maxInstallationsCount', e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Product Tags" name="tags">
                <Select
                  onChange={(e) => updateProduct('tags', e)}
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Put tags"
                />
              </Form.Item>
            </Col>
            <Col style={{'visibility': isDefault ? 'visible' : 'hidden'}}  className="gutter-row" span={6}>
              <Form.Item
                label="Menu index"                  
                name="menuIndex"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  prefix="$"
                  placeholder="Set index for tabbar menu"
                  min={0} max={5}
                  onChange={(e) => {
                    updateVirtualFeatures('menuIndex', e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col style={{'visibility': isDefault ? 'visible' : 'hidden'}}  className="gutter-row" span={6}>
              <Form.Item
                label="Sidebar index"                  
                name="sidebarIndex"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  prefix="$"
                  placeholder="Set index for sidebar menu"
                  min={0} max={10}
                  onChange={(e) => {
                    updateVirtualFeatures('sidebarIndex', e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>  
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>

          </Form>
          <br />
          <Button type="primary" onClick={() => setIsShowModal(true)}>
            Add Version
          </Button>
          <br />
          <br />

          <Table
            columns={columns}
            bordered
            dataSource={listVersions}
            loading={isLoadingAddVersion}
            rowKey={(data) => {
              return data.info.id;
            }}
          />          
        </>
      )}
    </div>
  );
};

export default Product;
