import React, { useState } from 'react';
import { Form, Input, Modal, notification, Space } from 'antd';
import { adminAccount } from 'core/api/admin';
import { LocaleInfo, ProviderTranslation } from 'core/api/provider';


interface Props {
  id: string;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const AddAdminProviderModal: React.FC<Props> = function ({
  id,
  isModalVisible,
  handleOk,
  handleCancel
}) {

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const onSave = async() => {
      try {
        setConfirmLoading(true);
        const rv = await adminAccount.addAdmin(id, {email: email, password: password});
        setConfirmLoading(false);

        cleanForm();

        handleOk();

      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
        });
        setConfirmLoading(false);
      }

  };

  const cleanForm = () => {
    setEmail('');
    setPassword('');
  }

  return (
    <Modal
    title="Add admin provider"
    visible={isModalVisible}
    onOk={onSave}
    confirmLoading={confirmLoading}
    onCancel={() => {
      cleanForm(); 
      handleCancel()
    }}
  >
    <Form>
      <Space align='end' direction='vertical'>
        <Form.Item label="E-mail">
          <Input style={{width: '330px'}} placeholder="Type provider e-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </Form.Item>      
        <Form.Item label="Password">
          <Input style={{width: '330px'}} placeholder="Type default password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        </Form.Item>   
      </Space>
    </Form>
  </Modal>
  );
};

export default AddAdminProviderModal;
