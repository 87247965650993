import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, Form, Modal, Select } from 'antd';

import * as T from '../../types';

interface Props {
  isModalVisible: boolean;
  productsMaple: T.ProductMaple[];
  dataFormCreateProduct: {
    isVirtual: boolean;
    isDefault: boolean;
    isBoot: boolean;
    renewAvailable: boolean;
    serviceProductId: string;
  };
  setDataFormCreateProduct: Dispatch<SetStateAction<T.ProductForm>>;
  isNoBootYet: boolean;
  isDefaultAvailable: boolean;

  onOk(): void;
  handleCancel(): void;
}
const ModalCreateMiniApp: React.FC<Props> = function ({
  dataFormCreateProduct,
  isModalVisible,
  productsMaple,
  onOk,
  handleCancel,
  setDataFormCreateProduct,
  isNoBootYet,
  isDefaultAvailable,
}) {

  // useEffect(() => {
  //   setDataFormCreateProduct((data) => {
  //     return { ...data, isVirtual: true, isDefault: true, isBoot: isNoBootYet , renewAvailable: true };
  //   });
  // });

  const onChangeIsVirtual = (e: string) => {
    setDataFormCreateProduct((data) => {
      return { ...data, isVirtual: e === 'virtual' || e === 'boot' || e === 'default', isDefault: e === 'default' || e === 'boot', isBoot: e === 'boot' };
    });
  };

  const onChangeRenewAvailable = (renewAvailable: boolean) => {
    setDataFormCreateProduct((data) => {
      return { ...data, renewAvailable };
    });
  };

  const onChangeServiceProductId = (serviceProductId: string) => {
    setDataFormCreateProduct((data) => {
      return { ...data, serviceProductId };
    });
  };

  return (
    <Modal title="Create Mini App" visible={isModalVisible} onOk={onOk} onCancel={handleCancel} okText={'Next'}>
      <Form layout="vertical">
        <Form.Item label="Type of miniapp" name={'isVirtual'}>
          {/* <Select defaultValue={isNoBootYet ? 'boot' : 'virtual'} onChange={(e) => onChangeIsVirtual(e)}> */}
          <Select placeholder="Select a type" value='' onChange={(e) => onChangeIsVirtual(e)}>
          
            <Select.Option style={{'display': isNoBootYet ? 'inline' : 'none'}} value="boot">Boot</Select.Option>
            <Select.Option style={{'display': isDefaultAvailable ? 'inline' : 'none'}} value="default">Default</Select.Option>
            <Select.Option value="virtual">Virtual</Select.Option>
            {localStorage.getItem('apiKey') && <Select.Option value="formfactor">Form-factor</Select.Option>}
          </Select>
        </Form.Item>
        {!dataFormCreateProduct.isVirtual && (
          <Form.Item label="Related product" name={'serviceProductId'}>
            <Select defaultValue={dataFormCreateProduct.serviceProductId} onChange={(e) => onChangeServiceProductId(e)}>
              {productsMaple.map((e) => {
                return (
                  <Select.Option value={e.id} key={e.id}>
                    {e.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item name={'renewAvailable'}>
          <Checkbox
            checked={dataFormCreateProduct.isVirtual ? true : dataFormCreateProduct.renewAvailable}
            onChange={(e) => onChangeRenewAvailable(e.target.checked)}
          >
            Can be re-installed
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateMiniApp;
