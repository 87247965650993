import React from "react";
import { Link } from "react-router-dom";

import { DesktopOutlined, UserOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from "antd";

import ProviderList from "modules/cedarProviders/ProviderList";
import UsersList from "modules/cedarUsers/UsersList";
import SubMenu from "antd/lib/menu/SubMenu";
import AccountDetails from "modules/accountDetails/AccountDetails";
import { providerAccount, UserRoles } from "core/api/provider";
import ProductList from "modules/productList/ProductList";
import Scripts from "modules/scripts/Scripts";
import Templates from "modules/templates/Templates";
import DeviceHandlers from "modules/deviceHandlers/DeviceHandlers";
import BinaryImages from "modules/binaryImages/BinaryImages";
import CloudHandlers from "modules/cloudHandlers/CloudHandlers";


const AllUsers = [UserRoles.Admin, UserRoles.Developer, UserRoles.MapleDeveloper];

interface MenuItems
{
    url: string;
    name: string;
    rights: UserRoles[];
    icon: any;
    tsx: any;
}

export class MenuBuilder  {

    private accountSubMenu: MenuItems[] = [
        {
            url: '/dashboard/cedar/account',
            name: 'Details',
            rights: AllUsers,
            icon: <></>,
            tsx: <AccountDetails/>
        },
        {
            url: '/dashboard/cedar/users',
            name: 'Users',
            rights: [UserRoles.Admin],
            icon: <></>,
            tsx: <UsersList/>
        }
    ];

    private productSubMenu: MenuItems[] = [
        {
            url: '/dashboard/miniapps',
            name: 'MiniApps',
            rights: [UserRoles.Developer],
            icon: <></>,
            tsx: <ProductList/>
        },
        {
            url: '/dashboard/cloud_handlers',
            name: 'Cloud Handlers',
            rights: [UserRoles.MapleDeveloper],
            icon: <></>,
            tsx: <CloudHandlers/>
        },
        {
            url: '/dashboard/binary_images',
            name: 'Binary Images',
            rights: [UserRoles.MapleDeveloper],
            icon: <></>,
            tsx: <BinaryImages/>
        },
        {
            url: '/dashboard/device_handlers',
            name: 'Device Handlers',
            rights: [UserRoles.MapleDeveloper],
            icon: <></>,
            tsx: <DeviceHandlers/>
        },
        {
            url: '/dashboard/templates',
            name: 'Templates',
            rights: [UserRoles.MapleDeveloper],
            icon: <></>,
            tsx: <Templates/>
        },
        {
            url: '/dashboard/scripts',
            name: 'Scripts',
            rights: [UserRoles.MapleDeveloper],
            icon: <></>,
            tsx: <Scripts/>
        },
    ];

    public AccountMenu(invalidate: boolean)
    {            
        // console.log('AccountSubmenu invalidate: ' + invalidate);

        if(invalidate === true)
        {
            return(
                <>
                    <SubMenu key="sub1" title="Account" icon={<UserOutlined />}>       
                        {            
                            this.accountSubMenu.map((element, index, array) => {
                            
                                if(providerAccount.isAnyRoleIncluded(element.rights))
                                {
                                    return(
                                        <Menu.Item key={element.url} icon={element.icon}>
                                            {element.name}
                                            <Link to={element.url} />
                                        </Menu.Item>  
                                    ) 
                                }
                            })            
                        }
                    </SubMenu>
                </>
            );   
        }
        else
             return(
                <>
                </>
            );   
    }

    public ProvidersMenu(invalidate: boolean)
    {
        const url = '/dashboard/cedar/providers';
        const icon = <DesktopOutlined/>;
        const name = 'Providers';
        const tsx = <ProviderList/>;

        // console.log('is owner: ' + providerAccount.isAnyRoleIncluded([UserRoles.Owner]))

        if(providerAccount.isAnyRoleIncluded([UserRoles.Owner]) && invalidate)
        {
            // console.log("Add providers");
            return(        
                <Menu.Item key={url} icon={icon}>
                    {name}
                    <Link to={url} />
                </Menu.Item>  
            )
        }
        else
        {
            // console.log("Empty");
            return(        
            <></>
            )
        }
    }

    public ProductManagementMenu(invalidate: boolean)
    {
        if(providerAccount.isAnyRoleIncluded([UserRoles.Developer]) && invalidate)
        {
            console.log("Add product management");
            return(        
                <>
                    <SubMenu key="sub2" title="Product Management" icon={<AppstoreOutlined />}>       
                        {            
                            this.productSubMenu.map((element, index, array) => {
                            
                                if(providerAccount.isAnyRoleIncluded(element.rights))
                                {
                                    return(
                                        <Menu.Item key={element.url} icon={element.icon}>
                                            {element.name}
                                            <Link to={element.url} />
                                        </Menu.Item>  
                                    ) 
                                }
                            })            
                        }
                    </SubMenu>
                </>
            )
        }
        else
        {
            // console.log("Empty");
            return(        
            <></>
            )
        }
    }

}


export const menuBuilder = new MenuBuilder();