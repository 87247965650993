import { combineReducers } from 'redux';

import { userReducer, StateUser } from './state/user/reducer';
import { productReducer, StateProduct } from './state/product/reducer';

export interface StateRedux {
  coreReducer: {
    userReducer: StateUser;
    productReducer: StateProduct;
  };
}

export default combineReducers<StateRedux['coreReducer']>({
  userReducer,
  productReducer,
});
