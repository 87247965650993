import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, Typography, Form, Button, List, Select, Space, Input, Table, Checkbox } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import * as T from '../../types';
import ModalEditValue from '../../components/components/ModalEditValue/ModalEditValue';
import {on} from "cluster";

interface Props {
  isShowModal: boolean;
  templates: T.Template[];
  binaryImages: T.BinaryImages[];
  selectedScript: T.Script | undefined;
  setSelectedScript: Dispatch<SetStateAction<T.Script | undefined>>;

  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  onPostScript(data: T.ScriptForPost): void;
}

const ModalAddScript: React.FC<Props> = React.memo(
  ({ isShowModal, binaryImages, setIsShowModal, templates, onPostScript, selectedScript }) => {
    const { Option } = Select;

    const [nameTemplate, setNameTemplate] = useState<string>('');
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<T.Template | undefined>(undefined);

    const [requiredVariables, setRequiredVariables] = useState<T.Variable[]>([]);
    const [optionalVariables, setOptionalVariables] = useState<T.Variable[]>([]);
    const [binaryImgVariables, setBinaryImgVariables] = useState<T.Variable[]>([]);
    const [variables, setVariables] = useState<T.Variable[]>([]);
    const [chooseName, setChooseName] = useState<string>('');
    const [isShowModalEdit, setIsShowModalEdit] = useState<boolean>(false);

    useEffect(() => {
      if (selectedScript) {
        setNameTemplate(selectedScript.name);
        setIsPublic(selectedScript.is_private);
        const selectedTemp = templates.find((e) => e.id === selectedScript.template_id);
        setSelectedTemplate(selectedTemp);
        setIsPublic(selectedScript.is_private);

        if (selectedScript.required_variable_ids) {
          setRequiredVariables(
            selectedScript.required_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedScript.optional_variable_ids) {
          setOptionalVariables(
            selectedScript.optional_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedScript.binary_images) {
          setBinaryImgVariables(
            selectedScript.binary_images.map((e) => {
              return {
                name: e.id,
                value: e.binary_image_id,
                key: e.binary_image_id,
              };
            }),
          );
        }
        if (selectedScript.variables) {
          const variablesArr = [];
          for (let val in selectedScript.variables) {
            variablesArr.push({
              name: val,
              value: selectedScript.variables[val],
              key: selectedScript.variables[val],
            });
          }
          setVariables(variablesArr);
        }
      }
    }, [selectedScript]);

    const onClose = () => {
      setIsShowModal(false);
      resetData();
    };

    const resetData = () => {
      setIsPublic(false);
      setSelectedTemplate(undefined);
      setRequiredVariables([]);
      setOptionalVariables([]);
      setBinaryImgVariables([]);
      setVariables([]);
      setChooseName('');
      setNameTemplate('');
    };

    useEffect(() => {
      if (selectedTemplate) {
        if (selectedTemplate.required_variable_ids) {
          setRequiredVariables(
            selectedTemplate.required_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedTemplate.optional_variable_ids) {
          setOptionalVariables(
            selectedTemplate.optional_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedTemplate.binary_images) {
          setBinaryImgVariables(
            selectedTemplate.binary_images.map((e) => {
              return {
                name: e.id,
                value: e.binary_image_id,
                key: e.binary_image_id,
              };
            }),
          );
        }
        if (selectedTemplate.variables) {
          const variablesArr = [];
          for (let val in selectedTemplate.variables) {
            variablesArr.push({
              name: val,
              value: selectedTemplate.variables[val],
              key: selectedTemplate.variables[val],
            });
          }
          setVariables(variablesArr);
        }
      }
    }, [selectedTemplate]);

    const onChangeName = (name: string) => {
      setNameTemplate(name);
    };

    const onChangeIsPublic = (isPub: boolean) => {
      setIsPublic(isPub);
    };

    const onOpenModalEdit = (id: string) => {
      setChooseName(id);
      setIsShowModalEdit(true);
    };

    const onSaveValue = (value: string) => {
      const isBinary = binaryImgVariables.find((e) => e.name === chooseName);
      if (isBinary) {
        const newBinaryImages = binaryImgVariables.filter((e) => e.name !== chooseName);
        setBinaryImgVariables(newBinaryImages);
        const newElem = { ...isBinary };
        newElem.value = value;
        const newVariables = [...variables];
        newVariables.push(newElem);
        setVariables(newVariables);
        return;
      }
      const elem = findAndRemoveInArr();
      if (elem) {
        const newElem = { ...elem };
        newElem.value = value;
        const newVariables = [...variables];
        newVariables.push(newElem);
        setVariables(newVariables);
        return;
      }

      setVariables((arr) => {
        return arr.map((e) => {
          if (e.name === chooseName) {
            return { ...e, value };
          }
          return { ...e };
        });
      });
    };

    const findAndRemoveInArr = () => {
      const arr = [...requiredVariables, ...optionalVariables];
      const newRequiredVariables = requiredVariables.filter((e) => e.name !== chooseName);
      const newOptionalVariables = optionalVariables.filter((e) => e.name !== chooseName);
      setRequiredVariables(newRequiredVariables);
      setOptionalVariables(newOptionalVariables);

      return arr.find((e) => e.name === chooseName);
    };

    const onSaveBinaryImage = (value: string) => {
      const isVariable = variables.find((e) => e.name === chooseName);
      if (isVariable) {
        const newVariables = variables.filter((e) => e.name !== chooseName);
        setVariables(newVariables);
        const newElem = { ...isVariable };
        newElem.value = value;
        const actualBinaryImgVariables = [...binaryImgVariables];
        actualBinaryImgVariables.push(newElem);
        setBinaryImgVariables(actualBinaryImgVariables);
        return;
      }

      const elem = findAndRemoveInArr();
      if (elem) {
        const newElem = { ...elem };
        newElem.value = value;
        const actualBinaryImgVariables = [...binaryImgVariables];
        actualBinaryImgVariables.push(newElem);
        setBinaryImgVariables(actualBinaryImgVariables);
        return;
      }
      setBinaryImgVariables((arr) => {
        return arr.map((e) => {
          if (e.name === chooseName) {
            return { ...e, value };
          }
          return { ...e };
        });
      });
    };

    const onSave = () => {
      const variablesFoPost: { [key: string]: string } = {};

      requiredVariables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });
      optionalVariables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });
      variables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });

      const binary_images = binaryImgVariables.map((e) => {
        return {
          id: e.name,
          binary_image_id: e.value,
        };
      });

      if (selectedTemplate) {
        const data = {
          id: selectedScript ? selectedScript.id : uuidv4(),
          template_id: selectedTemplate.id,
          name: nameTemplate,
          is_private: isPublic,
          variables: variablesFoPost,
          binary_images,
        };

        onPostScript(data);
        onClose();
      }
    };

    return (
      <Modal
        width={800}
        visible={isShowModal}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={onSave} disabled={!nameTemplate.length}>
            Save
          </Button>,
        ]}
      >
        <ModalEditValue
          binaryImages={binaryImages}
          isModalVisible={isShowModalEdit}
          setIsModalVisible={setIsShowModalEdit}
          onSaveValue={onSaveValue}
          onSaveBinaryImage={onSaveBinaryImage}
        />
        <br />
        <Input
          placeholder="enter template name"
          addonBefore={'Script name'}
          value={nameTemplate}
          onChange={(e) => onChangeName(e.target.value)}
        />
        <br />
        <br />
        <Checkbox checked={isPublic} defaultChecked={isPublic} onChange={(e) => onChangeIsPublic(e.target.checked)}>
          Public script
        </Checkbox>
        <br />
        <br />
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Select a template"
          onChange={(e) => {
            const r = templates.find((t) => t.id === e);
            setSelectedTemplate(r);
          }}
        >
          {templates.map((e) => {
            return (
              <Option value={e.id} key={e.id}>
                {e.name}
              </Option>
            );
          })}
        </Select>
        <br />
        <br />
        <Typography.Title level={5}>Required variables:</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
            { title: 'Value', dataIndex: 'value', key: 'value' },
            {
              title: 'Actions',
              render: (e) => {
                return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
              },
            },
          ]}
          dataSource={requiredVariables}
        />
        <br />
        <Typography.Title level={5}>Optional variables:</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
            { title: 'Value', dataIndex: 'value', key: 'value' },
            {
              title: 'Actions',
              render: (e) => {
                return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
              },
            },
          ]}
          dataSource={optionalVariables}
        />
        <br />
        <Typography.Title level={5}>Binary images:</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
            { title: 'Value', dataIndex: 'value', key: 'value' },
            {
              title: 'Actions',
              render: (e) => {
                return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
              },
            },
          ]}
          dataSource={binaryImgVariables}
        />
        <br />
        <Typography.Title level={5}>Variables:</Typography.Title>
        <Table
          pagination={false}
          size="small"
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
            { title: 'Value', dataIndex: 'value', key: 'value' },
            {
              title: 'Actions',
              render: (e) => {
                return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
              },
            },
          ]}
          dataSource={variables}
        />
      </Modal>
    );
  },
);

export default ModalAddScript;
