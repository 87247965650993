import { put, call, takeLatest, all } from 'redux-saga/effects';

import { IAction } from '../../types';
import * as T from './types';
import * as fetchProduct from './fetch';

export function* rootSagaProduct() {
  yield all([takeLatest(T.FETCH_GET_LOCATE, onFetchGetLocate)]);
  yield all([takeLatest(T.FETCH_GET_PRODUCT_TYPE, onFetchGetProductType)]);
}

function* onFetchGetLocate(action: IAction) {
  try {
    const locale: T.Locale[] = yield call(fetchProduct.fetchGetLocate);
    yield put({ type: T.FETCH_GET_LOCATE_SUCCESS, payload: { locale } });
  } catch (error) {
    yield put({ type: T.FETCH_GET_LOCATE_FAILURE });
  }
}

function* onFetchGetProductType(action: IAction) {
  try {
    const productType: T.ProductType[] = yield call(fetchProduct.fetchGetProductType);
    yield put({ type: T.FETCH_GET_PRODUCT_TYPE_SUCCESS, payload: { productType } });
  } catch (error) {
    yield put({ type: T.FETCH_GET_PRODUCT_TYPE_FAILURE });
  }
}
