import React, { ReactNode, useEffect, useState } from 'react';
import { block } from 'bem-cn';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { DesktopOutlined, UserOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';

import { menuBuilder } from './menuBuilder';

import logo from './assets/logo.png';

import './dashboard.scss';
import { providerAccount, UserRoles } from 'core/api/provider';
const dashboard = block('dashboard');

interface Props {
  children: ReactNode;
}

const Dashboard: React.FC<Props> = function (props) {
  const location = useLocation();
  const { Sider, Content } = Layout;
  const { SubMenu } = Menu;

  const history = useHistory();
  const { children } = props;

  const [invalidate, setInvalidate] = useState<boolean>(false);


  const logout = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    history.push('/');
  };


  const init = async () => {
    try {
      const rv = await providerAccount.getInfo();
      // const rv = await providerAccount.checkRole(UserRoles.Admin);
      // console.log('init: ' + rv);
      setInvalidate(true);

      // just for test
      // setTimeout(() => {
      //   setInvalidate(false);
      // }, 5000);

    } catch (error) {
      
    }
  }
  
  useEffect(() => {
    init();
  }, []);




  return (
    <Layout className={dashboard()}>
      <Sider theme={'light'} width={236}>
        <div className={dashboard('logo')}>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <Menu
          mode='inline'
          defaultSelectedKeys={['/dashboard/cedar/account']}
          defaultOpenKeys={['sub1']}
        >
          {menuBuilder.AccountMenu(invalidate)}
          {menuBuilder.ProvidersMenu(invalidate)}
          {menuBuilder.ProductManagementMenu(invalidate)}



          <Menu.Item key='logout' icon={<LogoutOutlined/>} onClick={logout} style={{marginTop: '10px'}}>
            Logout
          </Menu.Item> 

        </Menu>

      </Sider>
      <Layout>
        <Content className={dashboard('content').toString()}>
          <div className={dashboard('layout').toString()} style={{ margin: '10px 0 0 0', padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
