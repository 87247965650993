import { Http } from './http';
import { settings } from '../settings';

export class Resource extends Http {
  public token: string;

  public constructor() {
    super(settings.baseURL);
    this.token = Resource.getToken();

    this.request.interceptors.request.use(config => {
      const headers = Object.assign({}, config.headers, {
        authorization: this.token,
        'api-version': settings['api-version'],
      });

      return {
        ...config,
        headers,
      };
    });

    this.request.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            window.location.href = '/';
          }
          return Promise.reject(error.response);
        },
    );
  }

  static getToken(): string {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      return `Bearer ${accessToken}`;
    }

    return ``;
  }
}

export const resource = new Resource();
