export const FETCH_GET_PRODUCT_TYPE = 'FETCH_GET_PRODUCT_TYPE';
export const FETCH_GET_PRODUCT_TYPE_SUCCESS = 'FETCH_GET_PRODUCT_TYPE_SUCCESS';
export const FETCH_GET_PRODUCT_TYPE_FAILURE = 'FETCH_GET_PRODUCT_TYPE_FAILURE';

export const FETCH_GET_LOCATE = 'LOCATE';
export const FETCH_GET_LOCATE_SUCCESS = 'FETCH_GET_LOCATE_SUCCESS';
export const FETCH_GET_LOCATE_FAILURE = 'FETCH_GET_LOCATE_FAILURE';

export const SAVE_DATA_FOR_CREATE_PRODUCT = 'SAVE_DATA_FOR_CREATE_PRODUCT';

export interface ProductType {
  info: {
    id: string;
    name: string;
  };
}
export interface Locale {
  info: {
    code: string;
    name: string;
  };
}

export interface Translation {
  localeCode: string;
  comment: string;
  name: string;
}

export interface ProductCreate {
  maxInstallationsCount: number;
  productTypeIds: number[];
  renewAvailable: boolean;
  translations: Translation[];
  virtualFeatures: {
    isBoot: boolean,
    isDefault: boolean,
    menuIndex?: number | null,
    sidebarIndex?: number | null,
  } | null,
  wearableFeatures: {
    hasGeneralCommands: boolean;
    hasKeys: boolean;
    hasSystemCommands: boolean;
    hasJsCommands: boolean;    
    hasSeCommands: boolean;
    hasFingerprint: boolean;
  };
  tags: string[];
  serviceProductId: string | null;
}