import * as T from './types';

export function fetchGetProductType() {
  return {
    type: T.FETCH_GET_PRODUCT_TYPE,
  };
}

export function fetchGetLocale() {
  return {
    type: T.FETCH_GET_LOCATE,
  };
}

export function saveDataForCreateProduct(payload: { productCreate: T.ProductCreate | null }) {
  return {
    type: T.SAVE_DATA_FOR_CREATE_PRODUCT,
    payload,
  };
}
