import { mapleResource } from 'core/api/mapleResource';

export function getJerryScript<T>() {
  const url = `/api/admin/jerryscripts`;
  return mapleResource.get<T>(url);
}

export function postJerryScript<T>(formData: FormData) {
  const url = `/api/admin/jerryscript`;
  return mapleResource.post<T>(url, formData);
}

export function deleteJerryScript<T>(id: string) {
  const url = `/api/admin/jerryscript/${id}`;
  return mapleResource.delete<T>(url);
}

export function getJerryScriptArchive<T>(id: string) {
  const url = `/api/admin/jerryscript/${id}/archive`;
  return mapleResource.get<T>(url);
}
