import { mapleResource } from 'core/api/mapleResource';
import * as T from '../types';

export async function getScripts<T>() {
  const url = `/api/admin/scripts`;
  return mapleResource.get<T>(url);
}

export async function getTemplates<T>() {
  const url = `/api/admin/script/templates`;
  return mapleResource.get<T>(url);
}

export function getBinaryImages<T>() {
  const url = `/api/admin/binaryimages`;
  return mapleResource.get<T>(url);
}

export async function postScript<T>(data: T.ScriptForPost) {
  const url = `/api/admin/script`;

  return mapleResource.post<T>(url, data);
}

export async function deleteScript<T>(id: string) {
  const url = `/api/admin/script/${id}`;

  return mapleResource.delete<T>(url);
}
