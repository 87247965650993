import { mapleResource } from 'core/api/mapleResource';
import { DataPostTemplate } from '../types';

export function getTemplates<T>() {
  const url = `/api/admin/script/templates`;
  return mapleResource.get<T>(url);
}

export function getScriptHandlers<T>() {
  const url = `/api/admin/script/handlers`;
  return mapleResource.get<T>(url);
}

export function getBinaryImages<T>() {
  const url = `/api/admin/binaryimages`;
  return mapleResource.get<T>(url);
}

export function postTemplate<T>(data: DataPostTemplate) {
  const url = `/api/admin/script/template`;
  return mapleResource.post<T>(url, data);
}

export function deleteTemplate<T>(id: string) {
  const url = `/api/admin/script/template/${id}`;
  return mapleResource.delete<T>(url);
}
