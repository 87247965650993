import React, { useState } from 'react';
import { Button, Space, Table } from 'antd';

import { Translation } from 'core/state/product/types';
import ModalLanguage from './components/ModalLanguage/ModalLanguage';

interface Props {
  translations: Translation[];
  updateProductCrateData(name: string, data: any): void;
}

const Language: React.FC<Props> = function ({ translations, updateProductCrateData }) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<Translation | null>(null);
  const [index, setIndex] = useState<number>(-1);

  const onUpdateTranslations = (event: 'add' | 'update' | 'remove', data: Translation, index?: number) => {
    if (event === 'add') {
      updateProductCrateData('translations', [...translations, data]);
    }

    if (event === 'remove') {
      updateProductCrateData(
        'translations',
        translations.filter((d, i) => i !== index),
      );
    }

    if (event === 'update') {
      updateProductCrateData(
        'translations',
        translations.map((d, i) => {
          if (i === index) {
            return data;
          }
          return d;
        }),
      );
    }
    onResetSelectedLang();
  };

  const onResetSelectedLang = () => {
    setSelectedLang(null);
    setIndex(-1);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Language',
      dataIndex: 'localeCode',
      key: 'localeCode',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: Translation, i: number) => {
        return (
          <Space size="middle">
            <Button type={'primary'} style={{textAlign:'center'}} 
              onClick={() => {
                setSelectedLang(data);
                setIndex(i);
                setIsModalVisible(true);
              }}
            >
              Edit
            </Button>
            <Button type={'primary'} style={{textAlign:'center'}} onClick={() => onUpdateTranslations('remove', data, i)}>Delete</Button>
          </Space>
        );
      },
    },
  ];

  const getLocaleCodeArr = () => {
    if (translations.length > 0) {
      if (translations.some((e) => e.localeCode === 'en-US')) {
        return ['ru-RU'];
      }
      return ['en-US'];
    }
    return ['en-US', 'ru-RU'];
  };

  return (
    <>
      <ModalLanguage
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onUpdateTranslations={onUpdateTranslations}
        selectedLang={selectedLang}
        index={index}
        onResetSelectedLang={onResetSelectedLang}
        localeCodeArr={getLocaleCodeArr()}
      />
      {translations.length < 2 && (
        <Button
          type="primary"
          style={{ marginBottom: 16 }}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Language
        </Button>
      )}

      <Table columns={columns} bordered dataSource={translations} pagination={false} />
    </>
  );
};

export default Language;
