import { mapleResource } from 'core/api/mapleResource';

export function getScripts<T>(variableId?: string) {
  const variableParams = variableId ? `?variable_id=${variableId}` : '';
  const url = `/api/admin/script/handlers${variableParams}`;
  return mapleResource.get<T>(url);
}

export function deleteScripts<T>(id: string) {
  const url = `/api/admin/script/handler/${id}`;
  return mapleResource.delete<T>(url);
}

export function postScript<T>(id: string, js: string) {
  const url = `/api/admin/script/handler/javascript?id=${id}`;
  const formData = new FormData();
  formData.append('js', new Blob([js], { type: 'text/plain' }), 'file.js');

  return mapleResource.post<T>(url, formData);
}

export function getScriptHandler<T>(id: string) {
  const url = `/api/admin/script/handler/${id}/javascript`;
  return mapleResource.get<T>(url);
}

export function postScriptHandlers<T>() {
  const url = '/api/admin/script/handlers/embedded';
  //const data = ['add_and_update_mifare_classic_mdac', 'upload_applet', 'reset_attack_counter'];
  const data: any[] = [];
  return mapleResource.post<T>(url, data);
}
