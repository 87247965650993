import React, { useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import * as T from './types';
import { getScripts, getTemplates, postScript, getBinaryImages, deleteScript } from './resource/resource';
import ModalAddScript from './components/ModalAddScript/ModalAddScript';

const Scripts: React.FC = function () {
  const { Title } = Typography;

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [templates, setTemplates] = useState<T.Template[]>([]);
  const [binaryImages, setBinaryImages] = useState<T.BinaryImages[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');
  const [scripts, setScripts] = useState<T.Script[]>([]);
  const [selectedScript, setSelectedScript] = useState<T.Script | undefined>(undefined);

  const getData = async () => {
    try {
      setIsLoad(true);
      const { data } = await getScripts<T.Script[]>();
      setScripts(data);
      setIsLoad(false);
    } catch (e) {
      setIsLoad(false);
      notification.error({
        message: 'Error',
        description: e.message ? e.message : e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  useEffect(() => {
    const initTemplates = async () => {
      try {
        const data = await getTemplates<T.Template[]>();
        setTemplates(data.data);
      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.message ? e.message : e.statusText ? e.statusText.toString() : e.toString(),
        });
      }
    };

    async function getBinaryImage() {
      try {
        const { data } = await getBinaryImages<T.BinaryImages[]>();
        setBinaryImages(data);
      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.message ? e.message : e.statusText ? e.statusText.toString() : e.toString(),
        });
      }
    }

    getBinaryImage();
    getData();
    initTemplates();
  }, []);

  const onSelectScript = (script: T.Script) => {
    setSelectedScript(script);
    setIsShowModal(true);
  };

  const onPostScript = async (data: T.ScriptForPost) => {
    try {
      await postScript(data);
      if (selectedScript) {
        setSelectedScript(undefined);
        getData();
      }
    } catch (e) {
      console.log('e', e);
      notification.error({
        message: 'Error',
        description: e.message ? e.message : e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const onDeleteScript = async (id: string) => {
    try {
      setIsLoad(true);
      await deleteScript(id);
      getData();
      notification.success({
        message: 'Success',
      });
    } catch (e) {
      console.log('e', e);
      notification.error({
        message: 'Error',
        description: e.message ? e.message : e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'is_private',
      key: 'is_private',
      render: (r: boolean) => {
        return <p>{r ? 'private' : 'public'}</p>;
      },
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (t: string) => {
        return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: T.Script, i: number) => {
        return (
          <Space>
            <Button
              style={{width:'80px', textAlign:'center'}}
              type={'primary'}
              onClick={() => {
                onSelectScript(data);
              }}
            >
              Edit
            </Button>
            <Button
              style={{width:'80px', textAlign:'center'}}
              danger
              onClick={() => {
                onDeleteScript(data.id);
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = scripts;
  if (textSearch.length > 0) {
    list = scripts.filter((e) => {
      return e.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  return (
    <>
      <ModalAddScript
        selectedScript={selectedScript}
        setSelectedScript={setSelectedScript}
        binaryImages={binaryImages}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        templates={templates}
        onPostScript={onPostScript}
      />
      <Row>
        <Col span={6}>
          <Title level={4}>Scripts</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search" onSearch={onSearch} />
        </Col>
      </Row>
      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Script
      </Button>
      <br />
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Row>
                <Col span={6}>
                  <>
                    <p>
                      <b>protocols</b>
                    </p>
                    {record.protocols.join(' / ')}
                  </>
                </Col>
                <Col span={6}>
                  <>
                    <p>
                      <b>required_variable_ids</b>
                    </p>
                    {record.required_variable_ids
                      ? record.required_variable_ids.map((e, i) => {
                          return <p key={i}>{e}</p>;
                        })
                      : '-'}
                  </>
                </Col>
              </Row>
            );
          },
        }}
        dataSource={list}
        columns={columns}
        loading={isLoad}
        rowKey={'id'}
      />
    </>
  );
};

export default Scripts;
