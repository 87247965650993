import { resource } from 'core/api/resource';
import { mapleResource } from 'core/api/mapleResource';
import { VersionCreate, Translation, ProductCreate } from '../types';

export function getProductVersions<T>(id: string) {
  const url = '/api/developer/productVersion';

  const params = new URLSearchParams();
  params.append('productId', id);

  return resource.get<T>(url, { params });
}

export function getProductType<T>() {
  const url = '/api/developer/productType';
  return resource.get<T>(url);
}

export async function getProductsMaple<T>() {
  const url = `/api/products`;
  return mapleResource.get<T>(url);
}

export async function getProduct<T>(id: string) {
  const url = `/api/developer/product/${id}`;
  return resource.get<T>(url);
}

export async function putProduct<T>(id: string, data: ProductCreate) {
  const url = `/api/developer/product/${id}`;
  return resource.put<T>(url, data);
}

export async function loadIcon<T>(formData: FormData, id: string) {
  const url = `/api/storage/icon`;
  formData.append('productId', id);
  return resource.post<T>(url, formData);
}

export async function loadFile<T>(formData: FormData, id: string) {
  const url = `/api/storage/miniApp`;
  formData.append('productVersionId', id);
  return resource.post<T>(url, formData);
}

export function postProductVersions<T>(data: VersionCreate) {
  const url = '/api/developer/productVersion';
  return resource.post<T>(url, data);
}

export function putProductVersions<T>(id: string, data: { enabled: boolean; translations: Translation[] }) {
  const url = `/api/developer/productVersion/${id}`;
  return resource.put<T>(url, data);
}
