import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { Translation } from 'core/state/product/types';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  selectedLang: Translation | null;
  index: number;
  localeCodeArr: string[];
  onUpdateTranslations(event: 'add' | 'update', data: Translation, index?: number): void;
  onResetSelectedLang(): void;
}
const ModalLanguage: React.FC<Props> = function ({
  isModalVisible,
  setIsModalVisible,
  onUpdateTranslations,
  selectedLang,
  index,
  localeCodeArr,
  onResetSelectedLang,
}) {
  const [form] = Form.useForm();

  const dataInit = useMemo(() => {
    return {
      localeCode: localeCodeArr[0],
      comment: '',
      name: '',
    };
  }, [localeCodeArr]);

  const [data, setData] = useState<Translation>(dataInit);

  useEffect(() => {
    if (selectedLang) {
      setData(selectedLang);
      form.setFieldsValue({ ...selectedLang });
    } else {
      setData(dataInit);
      form.setFieldsValue(dataInit);
    }
  }, [selectedLang, localeCodeArr]);

  const onChangeData = (name: string, data: string) => {
    setData((d) => {
      return { ...d, [name]: data };
    });
  };

  const saveLang = () => {
    if (selectedLang) {
      onUpdateTranslations('update', data, index);
    } else {
      onUpdateTranslations('add', data);
    }

    setIsModalVisible(false);
  };

  const onCancel = () => {
    setIsModalVisible(false);
    onResetSelectedLang();
  };
  
  return (
    <Modal
      title="Language"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!data.localeCode.length || !data.name.length}
          onClick={saveLang}
        >
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical" initialValues={{ name: data.name }} form={form}>
        <Form.Item label="Name" name="name">
          <Input onChange={(e) => onChangeData('name', e.target.value)} />
        </Form.Item>
        <Form.Item label="Choose Language" name="localeCode">
          <Select disabled={index >= 0} onSelect={(e) => onChangeData('localeCode', e.toString())}>
            {localeCodeArr.map((e, i) => {
              return (
                <Select.Option value={e} key={i}>
                  {e === 'en-US' ? 'English - United State' : 'Русский - Россия'}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="comment">
          <TextArea value={data.comment} rows={3} onChange={(e) => onChangeData('comment', e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalLanguage;
