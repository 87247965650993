import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal, Select, Input } from 'antd';

import * as T from '../../../types';

interface Props {
  binaryImages: T.BinaryImages[];
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  onSaveValue(value: string): void;
  onSaveBinaryImage(value: string): void;
}

const ModalEditValue: React.FC<Props> = ({
  binaryImages,
  isModalVisible,
  setIsModalVisible,
  onSaveValue,
  onSaveBinaryImage,
}) => {
  const [type, setType] = useState<'raw' | 'hexstring' | 'binaryImage'>('raw');
  const [value, setValue] = useState<string>('');

  const handleOk = () => {
    setValue('');
    setIsModalVisible(false);
    if (type === 'hexstring') {
      onSaveValue(toHex(value));
    }
    if (type === 'binaryImage') {
      onSaveBinaryImage(value);
    }
    if (type === 'raw') {
      onSaveValue(value);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setValue('');
  };

  const toHex = (str: string) => {
    let arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  };

  const onSetValue = (value: string) => {
    setValue(value);
  };

  const onSetType = (e: 'raw' | 'hexstring' | 'binaryImage') => {
    setType(e);
    setValue('');
  };

  return (
    <>
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Select defaultValue={type} style={{ width: '100%' }} onChange={onSetType}>
          <Select.Option value="raw">Raw</Select.Option>
          <Select.Option value="hexstring">Hexstring</Select.Option>
          <Select.Option value="binaryImage">Binary Image</Select.Option>
        </Select>
        <br />
        <br />
        <p>CARD_AID</p>
        {type !== 'binaryImage' ? (
          <Input placeholder="enter card aid" value={value} onChange={(e) => onSetValue(e.target.value)} />
        ) : (
          <Select
            style={{ width: '100%' }}
            onSelect={(e) => {
              onSetValue(e.toString());
            }}
          >
            {binaryImages.map((e) => {
              return (
                <Select.Option key={e.id} value={e.id}>
                  {e.name}
                </Select.Option>
              );
            })}
          </Select>
        )}
      </Modal>
    </>
  );
};

export default ModalEditValue;
