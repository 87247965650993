import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import Editor from '@monaco-editor/react';

interface Props {
  isShowModal: boolean;
  editableData?: { id: string; js: string };
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  onSuccess(id: string, js: string): void;
  setEditableData: Dispatch<SetStateAction<{ id: string; js: string } | undefined>>;
}

const ModalAddScript: React.FC<Props> = React.memo(
  ({ isShowModal, setIsShowModal, onSuccess, editableData, setEditableData }) => {
    const [data, setData] = useState<{ id: string; js: string }>({
      id: '',
      js: '',
    });

    useEffect(() => {
      if (editableData) {
        setData({ id: editableData.id, js: editableData.js });
      }
    }, [editableData]);

    const onChangeData = (e: string, name: string) => {
      setData((d) => {
        return { ...d, [name]: e };
      });
    };

    const onHandlerSuccess = () => {
      onSuccess(data.id, data.js);
    };

    const onClose = () => {
      setEditableData(undefined);
      setIsShowModal(false);
    };


    // const [fileName, setFileName] = useState("./template.js");

const template = " \
// Register params\n\
function GetHandlerInfo() {\n\
    const info = { \n\
        required_variable_ids: [], // for example: 'CARD_AID' \n\
        optional_variable_ids: [], // for example 'SCP' \n\
        required_wrappers: [], // for example 'Se', 'Session' etc \n\
        timeout: 60 \n\
    }; \n\
    return JSON.stringify(info); \n\
} \n\
\n\
// Exec on call \n\
function Execute() {} \n\
"; 


    return (
      <Modal
        visible={isShowModal}
        onCancel={onClose}
        width={1200}
        style={{ top: 20 }}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
          <Button key="submit" type="primary" disabled={!data.id.length || !data.js.length} onClick={onHandlerSuccess}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Cloud Handler ID:" required>
            <Input
              disabled={!!editableData}
              value={editableData && editableData.id}
              placeholder="Put a new ID (name) for handler"
              onChange={(e) => onChangeData(e.target.value, 'id')}
            />
          </Form.Item>
          <Form.Item label="Handler Code:" required>
            <Editor
              value={editableData && editableData.js}
              height="600px"
              defaultLanguage="javascript"
              // path={fileName}
              defaultValue={template}
              onChange={(e) => {
                onChangeData(e ? e : '', 'js');
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);

export default ModalAddScript;
