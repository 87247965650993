import React, { useEffect, useState } from 'react';
import { Button, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { getBinaryImages, postBinaryImage, deleteBinaryImages } from './resource/resource';
import * as T from './types';
import ModalAddBinary from './components/ModalAddBinary/ModalAddBinary';

const BinaryImages: React.FC = function () {
  const { Title } = Typography;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [data, setData] = useState<T.BinaryImages[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');

  async function getData() {
    try {
      setIsLoading(true);
      const { data } = await getBinaryImages<T.BinaryImages[]>();
      setData(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const onAddBinaryImg = async (name: string, formData: FormData) => {
    try {
      await postBinaryImage(name, formData);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const onDelete = async (id: string) => {
    try {
      await deleteBinaryImages(id);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Data',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (t: string) => {
        return <span>{moment(t).format('dddd, MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, data: T.BinaryImages, i: number) => {
        return (
          <Space>
            <Button
              type={'primary'}
              style={{width:'80px', textAlign:'center'}}
              onClick={() => {
                navigator.clipboard.writeText(data.id);
              }}
            >
              Copy Id
            </Button>
            <Button danger style={{width:'80px', textAlign:'center'}}onClick={() => onDelete(data.id)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = data;
  if (textSearch.length > 0) {
    list = data.filter((e) => {
      return e.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
    });
  }

  return (
    <>
      <ModalAddBinary isShowModal={isShowModal} setIsShowModal={setIsShowModal} onAddBinaryImg={onAddBinaryImg} />

      <Row>
        <Col span={6}>
          <Title level={4}>Binary images</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search" onSearch={onSearch} />
        </Col>
      </Row>
      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Binary Image
      </Button>
      <br />
      <br />
      <Table
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <p>
                  <b>size:</b> {record.size}
                </p>
                <p>
                  <b>crc32:</b> {record.crc32}
                </p>
              </>
            );
          },
        }}
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default BinaryImages;
