import React, { useState } from 'react';
import { Form, Input, Modal, notification, Space } from 'antd';
import { userAccount } from 'core/api/account';


interface Props {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const UserPasswordModal: React.FC<Props> = function ({
  isModalVisible,
  handleOk,
  handleCancel
}) {


  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);


  const onSave = async() => {


      if(newPassword !== verifyPassword)
      {
        notification.error({        
          message: 'Error',
          description: 'New password and verify are not identical',
        });

        return;
      }

      try {
        setConfirmLoading(true);
        const rv = await userAccount.changePassword({currentPassword: currentPassword, newPassword: newPassword});
        setConfirmLoading(false);

        cleanForm();

        handleOk();

      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
        });
        setConfirmLoading(false);
      }

  };

  const cleanForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setVerifyPassword('');
  }


  return (
    <Modal
    title="Change user password"
    visible={isModalVisible}
    onOk={onSave}
    confirmLoading={confirmLoading}
    onCancel={() => {
      cleanForm(); 
      handleCancel()
    }}
  >
    <Form>
      <Space align='end' direction='vertical'>
        <Form.Item label="Current password">
          <Input.Password style={{width: '330px'}} placeholder="Type your curent password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
        </Form.Item>
        <Form.Item label="New password">
          <Input.Password style={{width: '330px'}} placeholder="Type your new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Verify password">
          <Input.Password style={{width: '330px'}} placeholder="Repeat your new password" value={verifyPassword} onChange={(e) => setVerifyPassword(e.target.value)}/>
        </Form.Item>      
      </Space>
    </Form>
  </Modal>
  );
};

export default UserPasswordModal;
