import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, notification, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';

import { adminAccount, AdminProviderInfo } from 'core/api/admin';
import {AccountLocale, LocaleInfo, providerAccount} from 'core/api/provider';
import AddProviderModal from './components/AddProviderModal'
import AddAdminProviderModal from './components/AddAdminProviderModal'

const ProviderList: React.FC = function () {
  const { Title } = Typography;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [addAdminModalVisible, setAddAdminModalVisible] = useState<boolean>(false);
  const [data, setData] = useState<AdminProviderInfo[]>([]);
  const [textSearch, setTextSearch] = useState<string>('');
  const [locale, setLocale] = useState<LocaleInfo[]>([]);
  const [editableProviderId, setEditableProviderId] = useState<string>('');


  async function getLocale() {
    try {
      setIsLoading(true);
      const {data} = await providerAccount.getLocale<AccountLocale>();
      setLocale(data.list);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  async function getData() {
    try {
      setIsLoading(true);
      const {data} = await adminAccount.getInfo<AdminProviderInfo[]>();
      setData(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    getLocale();
    getData();
  }, []);

  const onAddProvider = async (name: string, formData: FormData) => {
    try {
    //   await postBinaryImage(name, formData);
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
    getData();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Default apps',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (t: boolean) => {
          return <Checkbox disabled checked={t} onChange={() => console.log('3')}></Checkbox>;
      },
    },  
    {
        title: 'FF apps',
        dataIndex: 'hasMobyApiKey',
        key: 'hasMobyApiKey',
        render: (t: boolean) => {
            return <Checkbox disabled checked={t} onChange={() => console.log('2')}></Checkbox>;
        },
    },  
    {
      title: 'Activated',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (t: boolean) => {
        return <Checkbox disabled checked={t} onChange={() => console.log('3')}></Checkbox>;
      },
    },
    {
      title: 'Users',
      dataIndex: 'usersCount',
      key: 'usersCount',
      render: (t: number) => {
        return <span>{t}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: AdminProviderInfo) => {
        return (
          <Space>
            <Button
              danger = {data.enabled}
              disabled = {data.isDefault}
              type={data.enabled ? 'default' : 'primary'}
              style={{width:'80px', textAlign:'center'}}
              onClick={async () => {
                try {
                  const rv = await adminAccount.updateInfo(data.id, !data.enabled);
                  if(rv)
                    getData();
                } catch (error) {
                  
                }
              }}
            >
              {data.enabled ? "Disable" : "Enable"}              
            </Button>
            <Button type={'primary'} style={{textAlign:'center'}} onClick={() => {
              setEditableProviderId(data.id);
              setAddAdminModalVisible(true)}}>
              Add Admin
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    setTextSearch(value);
  };

  let list = data;
//   if (textSearch.length > 0) {
//     list = data.filter((e) => {
//       return e.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
//     });
//   }

  const handleUserAddOk = () => {
    setIsShowModal(false);
    getData();
  }

  return (
    <>
      <AddProviderModal isModalVisible={isShowModal} handleCancel={() => setIsShowModal(false)} handleOk={() => handleUserAddOk()} locale={locale}/>

      <AddAdminProviderModal isModalVisible={addAdminModalVisible} id={editableProviderId} handleCancel={() => setAddAdminModalVisible(false)} handleOk={() => { setAddAdminModalVisible(false);
      getData()}}/>
      <Title level={4}>Providers</Title>

      {/* <Row>
        <Col span={6}>
          <Title level={4}>Binary images</Title>
        </Col>
        <Col span={18}>
          <Input.Search allowClear placeholder="search" onSearch={onSearch} />
        </Col>
      </Row> */}

      <Button
        type={'primary'}
        onClick={() => {
          setIsShowModal(true);
        }}
      >
        Add Provider
      </Button>
      <br />
      <br />
      <Table
        dataSource={list}
        columns={columns}
        loading={isLoading}
        rowKey={'id'}
      />
    </>
  );
};

export default ProviderList;

