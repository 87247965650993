import * as resource from '../../resource';

export async function fetchGetProductType() {
  const response = await resource.getProductType<any>();
  return response.data.list;
}

export async function fetchGetLocate() {
  const response = await resource.getLocate<any>();
  return response.data.list;
}
