
import { notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  fileIcon?: FormData;
  nameInput?: string;
  fileUrl?: string;
  imageType?: boolean;

  setFileIcon(data: FormData): void;
}

const Dropzone: React.FC<Props> = function (Props) {
  const { setFileIcon, fileIcon, nameInput, fileUrl, imageType } = Props;
  const [fileShow, setFileShow] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');

  useEffect(() => {
    if (!fileIcon) {
      setFileShow('');
    }
  }, [fileIcon]);

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    const { type } = acceptedFiles[0];
    
    // const fileType = acceptedFiles[0].type;

    // console.log("load file: " + acceptedFiles[0].size + ": " + type + ": " + acceptedFiles[0].name + " is zip: " + fileType.includes("zip"));

    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      // reader.result contains the contents of blob as a typed array
      // console.log("content: " + reader.result);
   });
    reader.readAsArrayBuffer(acceptedFiles[0]);

    if(imageType)
    {
      if (type === 'image/png' || type === 'image/jpeg') {  
        setFileShow(URL.createObjectURL(acceptedFiles[0]));
        setFileType('img');
      }
      else
      {
        notification.error({
          message: 'Error',
          description: "Please choose a PNG or JPG file",
        });
      }
    } else {
      if(!acceptedFiles[0].type.includes("zip"))
      {
        notification.error({
          message: 'Error',
          description: "Please choose a ZIP file type",
        });
        
        return;
      }

      if(!acceptedFiles[0].name.includes(".zip"))
      {
        notification.error({
          message: 'Error',
          description: "Please choose a ZIP file",
        });
        
        return;
      }

      if(acceptedFiles[0].size > 10*1024*1024)
      {
        notification.error({
          message: 'Error',
          description: "We accept miniapp ZIP file up to 10MB, please check size of your file",
        });
        
        return;
      }


      setFileShow(acceptedFiles[0].name);
      setFileType('file');
    }
    formData.append(nameInput ? nameInput : 'file', acceptedFiles[0]);

    setFileIcon(formData);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
        <span tabIndex={0} className="ant-upload" role="button">
          <div>
            {!fileShow.length && !!fileUrl?.length && (
              <img src={fileUrl} alt="img" style={{ maxWidth: '100px', maxHeight: '100px' }} />
            )}
            {!!fileShow.length ? (
              fileType === 'img' ? (
                <img src={fileShow} alt="img" style={{ maxWidth: '100px', maxHeight: '100px' }} />
              ) : (
                fileShow
              )
            ) : isDragActive ? (
              <>
                <span role="img" aria-label="plus" className="anticon anticon-plus">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="plus"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
                    <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
                  </svg>
                </span>
                <div style={{ marginTop: '8px;' }}>Upload</div>
              </>
            ) : (
              <>
                <span role="img" aria-label="plus" className="anticon anticon-plus">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="plus"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
                    <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
                  </svg>
                </span>
                <div style={{ marginTop: '8px;' }}>Upload</div>
              </>
            )}
          </div>
        </span>
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

export default Dropzone;
