import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Modal, notification, Space } from 'antd';
import { providerAccount, UserRole } from 'core/api/provider';


interface Props {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}
const AddUserModal: React.FC<Props> = function ({
  isModalVisible,
  handleOk,
  handleCancel
}) {

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [roleIds, setRoleIds] = useState<number[]>([]);

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const onSave = async() => {
      try {
        setConfirmLoading(true);
        const rv = await providerAccount.addUser({firstName: firstName, lastName: lastName, email: email, password: password, roleIds: roleIds});
        setConfirmLoading(false);

        cleanForm();

        handleOk();

      } catch (e) {
        notification.error({
          message: 'Error',
          description: e.data.message ? e.data.message : ( e.statusText ? e.statusText.toString() : e.toString()) ,
        });
        setConfirmLoading(false);
      }

  };

  const cleanForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setRoleIds([]);
  }


  async function getData() {
    try {
      const { data } = await providerAccount.getUserRoles<UserRole[]>();
      setUserRoles(data);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.statusText ? e.statusText.toString() : e.toString(),
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const renderUserRoles = () => {
    try {
      const list = [{id:1, name: "Customer"}, {id:2, name: "Developer"}, {id:3, name: "Admin"}];
      return (
        <Space align='end' direction='vertical'>
          {
            userRoles.map((e) => {
            // list.map((e) => {              
              return <Checkbox style={{width: '330px'}} onChange={(status) => 
                {
                  console.log(e.id)

                  let roles = roleIds;                  
                  let roleExist = false;
                  roles.map((element, index) => {
                    if(element === e.id)
                    {
                      if(!status.target.checked)
                        roles.splice(index, 1);
                      
                      roleExist = true;
                    }
                  })

                  if(status.target.checked && !roleExist)
                    roles.push(e.id);

                  setRoleIds(roles);

                }
                }>{e.name}</Checkbox>;
            })
          }
        </Space>
      )

    } catch (error) {
      
    }
  }


  return (
    <Modal
    title="Add user"
    visible={isModalVisible}
    onOk={onSave}
    confirmLoading={confirmLoading}
    onCancel={() => {
      cleanForm(); 
      handleCancel()
    }}
  >
    <Form>
      <Space align='end' direction='vertical'>
        <Form.Item label="First name">
          <Input style={{width: '330px'}} placeholder="Type user first name" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Last name">
          <Input style={{width: '330px'}} placeholder="Type user last name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
        </Form.Item>
        <Form.Item label="E-mail">
          <Input style={{width: '330px'}} placeholder="Type user e-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </Form.Item>    
        <Form.Item label="Password">
          <Input style={{width: '330px'}} placeholder="Type default password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        </Form.Item>      
        <Form.Item label="User roles">
          {renderUserRoles()}
        </Form.Item>    
      </Space>
    </Form>
  </Modal>
  );
};

export default AddUserModal;
