import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider, useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { useSelector } from 'react-redux';

import 'antd/dist/antd.css';
import './global.scss';

// ------------------PAGES
import Login from 'pages/login/Login';
import Dashboard from 'pages/dashboard/Dashboard';

// ------------------modules
// import Documentation from 'modules/documentation/Documentation';
// import AccessToApi from 'modules/accessToApi/AccessToApi';
import ProductList from 'modules/productList/ProductList';
import Product from 'modules/product/Product';
import CreateMiniApp from 'modules/createMiniApp/CreateMiniApp';
import CloudHandlers from 'modules/cloudHandlers/CloudHandlers';
import BinaryImages from 'modules/binaryImages/BinaryImages';
import DeviceHandlers from 'modules/deviceHandlers/DeviceHandlers';
import Templates from 'modules/templates/Templates';
import Scripts from 'modules/scripts/Scripts';

import AccountDetails from 'modules/accountDetails/AccountDetails';

import coreReducer from './core/reducers';
import { rootSagaCore } from './core/sagas';
import { CoreReducer } from './core/types';
import { checkIsAuth } from 'core/state/user/actions';
import ProviderList from 'modules/cedarProviders/ProviderList';
import UsersList from 'modules/cedarUsers/UsersList';

const history = createBrowserHistory();
const reactRouterReduxMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middleware = [reactRouterReduxMiddleware, sagaMiddleware];

const composeEnhancers = composeWithDevTools({ actionsBlacklist: [] });
const reducer = combineReducers({
  coreReducer,
  router: connectRouter(history),
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSagaCore);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          {/* <PrivateRoute path="/dashboard/documentation">
            <Dashboard>
              <Documentation />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/access_to_api">
            <Dashboard>
              <AccessToApi />
            </Dashboard>
          </PrivateRoute> */}

          <PrivateRoute path="/dashboard/cedar/account">
            <Dashboard>
              <AccountDetails />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/cedar/users">
            <Dashboard>
              <UsersList />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/cedar/providers">
            <Dashboard>
              <ProviderList />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/miniapps">
            <Dashboard>
              <ProductList />
            </Dashboard>
          </PrivateRoute>
          <PrivateRoute path="/dashboard/cloud_handlers">
            <Dashboard>
              <CloudHandlers />
            </Dashboard>
          </PrivateRoute>
          <PrivateRoute path="/dashboard/binary_images">
            <Dashboard>
              <BinaryImages />
            </Dashboard>
          </PrivateRoute>
          <PrivateRoute path="/dashboard/device_handlers">
            <Dashboard>
              <DeviceHandlers />
            </Dashboard>
          </PrivateRoute>
          <PrivateRoute path="/dashboard/templates">
            <Dashboard>
              <Templates />
            </Dashboard>
          </PrivateRoute>
          <PrivateRoute path="/dashboard/scripts">
            <Dashboard>
              <Scripts />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/product/:id">
            <Dashboard>
              <Product />
            </Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/create_mini_app">
            <Dashboard>
              <CreateMiniApp />
            </Dashboard>
          </PrivateRoute>
        </Switch>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// TODO: need replace type
function PrivateRoute({ children, path, exact, ...rest }: { children: ReactNode; path: string; exact?: boolean }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: CoreReducer) => state.coreReducer.userReducer.isAuthenticated);
  const isLoadingAuthenticated = useSelector(
    (state: CoreReducer) => state.coreReducer.userReducer.isLoadingAuthenticated,
  );

  useEffect(() => {
    dispatch(checkIsAuth());
  });

  return !isLoadingAuthenticated ? (
    <Route {...rest} render={({ location }) => (isAuthenticated ? children : <Redirect to="/" />)} />
  ) : (
    <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
