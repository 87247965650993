import { put, call, takeLatest, all } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';


import * as T from '../../types';
import * as T_USERS from './types';
import * as fetchUser from './fetch';

export function* rootSagaUser() {
  yield all([takeLatest(T_USERS.FETCH_LOGIN, onFetchLogIn)]);
  yield all([takeLatest(T_USERS.CHECK_IS_AUTHENTICATED, onCheckIsAuth)]);
}

function* onFetchLogIn(action: T.IAction<T_USERS.LogInData>) {
  try {
    const {
      data: { accessToken, tokenType, refreshToken },
    } = yield call(fetchUser.fetchLogIn, action.payload);
    if (tokenType === 'Bearer') {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      if(accessToken !== null && accessToken !== undefined)
      {
        const user_data = jwt_decode(accessToken as string);
        const key = (user_data as any).moby_api_key
        localStorage.setItem('apiKey', key !== undefined ? key : '');
      }
      else
        localStorage.setItem('apiKey', '');
    }
    yield put({ type: T_USERS.FETCH_LOGIN_SUCCESS });
  } catch (error) {
    const message = error.response.data?.message;
    yield put({ type: T_USERS.FETCH_LOGIN_FAILURE, payload: { errorTextLogIn: message } });
  }
}

function* onCheckIsAuth() {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken) {
      yield put({ type: T_USERS.CHECK_IS_AUTHENTICATED_SUCCESS });
    }
    else {
      throw 'Authenticated failure';
    }
  } catch (error) {
    yield put({ type: T_USERS.CHECK_IS_AUTHENTICATED_FAILURE });
  }
}
