import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, Typography, Form, Button, List, Select, Space, Input, Table } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DropResult } from 'react-beautiful-dnd';
import { Collapse } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import * as T from '../../types';
import ModalEditValue from '../components/ModalEditValue/ModalEditValue';

interface Props {
  selectedTemplate: T.Template | undefined;
  handlers: T.Handlers[];
  binaryImages: T.BinaryImages[];
  isShowModal: boolean;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedTemplate: Dispatch<SetStateAction<T.Template | undefined>>;
  onPostTemplate(data: T.DataPostTemplate): void;
}

const ModalAddTemplate: React.FC<Props> = React.memo(
  ({ isShowModal, setIsShowModal, handlers, binaryImages, onPostTemplate, selectedTemplate, setSelectedTemplate }) => {
    const { Panel } = Collapse;

    const [items, setItem] = useState<T.Handlers[]>([]);
    const [selectedElem, setSelectedElem] = useState<string>('');
    const [requiredVariables, setRequiredVariables] = useState<T.Variable[]>([]);
    const [optionalVariables, setOptionalVariables] = useState<T.Variable[]>([]);
    const [binaryImgVariables, setBinaryImgVariables] = useState<T.Variable[]>([]);
    const [variables, setVariables] = useState<T.Variable[]>([]);

    const [isShowModalEdit, setIsShowModalEdit] = useState<boolean>(false);
    const [chooseName, setChooseName] = useState<string>('');
    const [nameTemplate, setNameTemplate] = useState<string>('');

    useEffect(() => {
      if (selectedTemplate) {
        setNameTemplate(selectedTemplate.name);
        setItem(selectedTemplate.handlers);

        if (selectedTemplate.required_variable_ids) {
          setRequiredVariables(
            selectedTemplate.required_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedTemplate.optional_variable_ids) {
          setOptionalVariables(
            selectedTemplate.optional_variable_ids.map((e) => {
              return {
                name: e,
                value: '',
                key: e,
              };
            }),
          );
        }
        if (selectedTemplate.binary_images) {
          setBinaryImgVariables(
            selectedTemplate.binary_images.map((e) => {
              return {
                name: e.id,
                value: e.binary_image_id,
                key: e.binary_image_id,
              };
            }),
          );
        }
        if (selectedTemplate.variables) {
          const variablesArr = [];
          for (let val in selectedTemplate.variables) {
            variablesArr.push({
              name: val,
              value: selectedTemplate.variables[val],
              key: selectedTemplate.variables[val],
            });
          }
          setVariables(variablesArr);
        }
      }
    }, [selectedTemplate]);

    const onClose = () => {
      setIsShowModal(false);
      resetData();
      setSelectedTemplate(undefined);
    };

    const resetData = () => {
      setItem([]);
      setSelectedElem('');
      setRequiredVariables([]);
      setOptionalVariables([]);
      setBinaryImgVariables([]);
      setVariables([]);
      setChooseName('');
      setNameTemplate('');
    };

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const newItems = reorder(items, result.source.index, result.destination.index);
      setItem(newItems);
    };

    const onChangeElement = (value: string) => {
      setSelectedElem(value);
    };

    const onAddHandler = () => {
      const item = handlers.find((e) => e.id === selectedElem)!;

      const list = [...items, item];
      setItem(list);
      setSelectedElem('');
      aggregateParameters(list);
    };

    const onDeleteHandler = (id: string) => {
      const list = items.filter((o) => o.id !== id);
      setItem(list);
      aggregateParameters(list);
    };

    const filteredHandlers = handlers.filter((o) => !items.includes(o));

    const aggregateParameters = (list: T.Handlers[]) => {
      let requiredVariable: string[] = [];
      let optionalVariable: string[] = [];

      list.forEach((e) => {
        e.required_variable_ids?.forEach((s) => requiredVariable.push(s));
        e.optional_variable_ids?.forEach((s) => optionalVariable.push(s));
      });

      requiredVariable = requiredVariable.filter((item, pos) => requiredVariable.indexOf(item) === pos);
      optionalVariable = optionalVariable.filter((item, pos) => optionalVariable.indexOf(item) === pos);

      setRequiredVariables(
        requiredVariable.map((e) => {
          const foundElement = requiredVariables.find((i) => i.name === e);
          if (foundElement) {
            return { name: e, value: foundElement.value, key: e };
          }
          return { name: e, value: '', key: e };
        }),
      );

      setOptionalVariables(
        optionalVariable.map((e) => {
          const foundElement = optionalVariables.find((i) => i.name === e);
          if (foundElement) {
            return { name: e, value: foundElement.value, key: e };
          }
          return { name: e, value: '', key: e };
        }),
      );
    };

    const onOpenModalEdit = (id: string) => {
      setChooseName(id);
      setIsShowModalEdit(true);
    };

    const findAndRemoveInArr = () => {
      const arr = [...requiredVariables, ...optionalVariables];
      const newRequiredVariables = requiredVariables.filter((e) => e.name !== chooseName);
      const newOptionalVariables = optionalVariables.filter((e) => e.name !== chooseName);
      setRequiredVariables(newRequiredVariables);
      setOptionalVariables(newOptionalVariables);

      return arr.find((e) => e.name === chooseName);
    };

    const onSaveValue = (value: string) => {
      const isBinary = binaryImgVariables.find((e) => e.name === chooseName);
      if (isBinary) {
        const newBinaryImages = binaryImgVariables.filter((e) => e.name !== chooseName);
        setBinaryImgVariables(newBinaryImages);
        const newElem = { ...isBinary };
        newElem.value = value;
        const newVariables = [...variables];
        newVariables.push(newElem);
        setVariables(newVariables);
        return;
      }
      const elem = findAndRemoveInArr();
      if (elem) {
        const newElem = { ...elem };
        newElem.value = value;
        const newVariables = [...variables];
        newVariables.push(newElem);
        setVariables(newVariables);
        return;
      }

      setVariables((arr) => {
        return arr.map((e) => {
          if (e.name === chooseName) {
            return { ...e, value };
          }
          return { ...e };
        });
      });
    };

    const onSaveBinaryImage = (value: string) => {
      const isVariable = variables.find((e) => e.name === chooseName);
      if (isVariable) {
        const newVariables = variables.filter((e) => e.name !== chooseName);
        setVariables(newVariables);
        const newElem = { ...isVariable };
        newElem.value = value;
        const actualBinaryImgVariables = [...binaryImgVariables];
        actualBinaryImgVariables.push(newElem);
        setBinaryImgVariables(actualBinaryImgVariables);
        return;
      }

      const elem = findAndRemoveInArr();
      if (elem) {
        const newElem = { ...elem };
        newElem.value = value;
        const actualBinaryImgVariables = [...binaryImgVariables];
        actualBinaryImgVariables.push(newElem);
        setBinaryImgVariables(actualBinaryImgVariables);
        return;
      }
      setBinaryImgVariables((arr) => {
        return arr.map((e) => {
          if (e.name === chooseName) {
            return { ...e, value };
          }
          return { ...e };
        });
      });
    };

    const onChangeName = (name: string) => {
      setNameTemplate(name);
    };

    const onAddTemplate = () => {
      const variablesFoPost: { [key: string]: string } = {};

      requiredVariables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });
      optionalVariables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });
      variables.forEach((e) => {
        if (!!e.value.length) {
          variablesFoPost[e.name] = e.value;
        }
      });

      const binary_images = binaryImgVariables.map((e) => {
        return {
          id: e.name,
          binary_image_id: e.value,
        };
      });

      const data = {
        id: selectedTemplate ? selectedTemplate.id : uuidv4(),
        name: nameTemplate,
        variables: variablesFoPost,
        binary_images,
        handlers: items.map((e) => {
          return { id: e.id };
        }),
      };

      onPostTemplate(data);
      onClose();
    };

    return (
      <Modal
        width={800}
        visible={isShowModal}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={onAddTemplate} disabled={!nameTemplate.length || !items.length}>
            Save
          </Button>,
        ]}
      >
        <ModalEditValue
          binaryImages={binaryImages}
          isModalVisible={isShowModalEdit}
          setIsModalVisible={setIsShowModalEdit}
          onSaveValue={onSaveValue}
          onSaveBinaryImage={onSaveBinaryImage}
        />
        <br />
        <Input
          placeholder="enter template name"
          addonBefore={'Template name'}
          value={nameTemplate}
          onChange={(e) => onChangeName(e.target.value)}
        />
        <br />
        <br />
        <Collapse defaultActiveKey={['2']}>
          <Panel header="Parameters" key="1">
            <Typography.Title level={5}>Required variables:</Typography.Title>
            <Table
              pagination={false}
              size="small"
              columns={[
                { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
                { title: 'Value', dataIndex: 'value', key: 'value' },
                {
                  title: 'Actions',
                  render: (e) => {
                    return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
                  },
                },
              ]}
              dataSource={requiredVariables}
            />
            <br />
            <Typography.Title level={5}>Optional variables:</Typography.Title>
            <Table
              pagination={false}
              size="small"
              columns={[
                { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
                { title: 'Value', dataIndex: 'value', key: 'value' },
                {
                  title: 'Actions',
                  render: (e) => {
                    return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
                  },
                },
              ]}
              dataSource={optionalVariables}
            />
            <br />
            <Typography.Title level={5}>Binary images:</Typography.Title>
            <Table
              pagination={false}
              size="small"
              columns={[
                { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
                { title: 'Value', dataIndex: 'value', key: 'value' },
                {
                  title: 'Actions',
                  render: (e) => {
                    return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
                  },
                },
              ]}
              dataSource={binaryImgVariables}
            />
            <br />
            <Typography.Title level={5}>Variables:</Typography.Title>
            <Table
              pagination={false}
              size="small"
              columns={[
                { title: 'Name', dataIndex: 'name', key: 'name', width: 300 },
                { title: 'Value', dataIndex: 'value', key: 'value' },
                {
                  title: 'Actions',
                  render: (e) => {
                    return <a onClick={() => onOpenModalEdit(e.name)}>Edit</a>;
                  },
                },
              ]}
              dataSource={variables}
            />
          </Panel>
          <Panel header="Handlers" key="2">
            <Typography.Title level={5}>Add handlers</Typography.Title>
            <Space>
              <Select
                showSearch
                style={{ width: 350 }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChangeElement}
                value={selectedElem}
              >
                {filteredHandlers.map((e) => {
                  return (
                    <Select.Option key={e.id} value={e.id}>
                      {e.id}
                    </Select.Option>
                  );
                })}
              </Select>
              <Button type="primary" onClick={onAddHandler}>
                Add handler
              </Button>
            </Space>
            <br /> <br />
            <Typography.Title level={5}>Handlers</Typography.Title>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <List
                      itemLayout="horizontal"
                      dataSource={items}
                      renderItem={(item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <List.Item
                                actions={[
                                  <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                      onDeleteHandler(item.id);
                                    }}
                                  >
                                    Delete
                                  </Button>,
                                ]}
                              >
                                <List.Item.Meta title={item.id} />
                              </List.Item>
                            </div>
                          )}
                        </Draggable>
                      )}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Panel>
        </Collapse>
      </Modal>
    );
  },
);

export default ModalAddTemplate;
