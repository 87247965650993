import { mapleResource } from 'core/api/mapleResource';

export function getBinaryImages<T>() {
  const url = `/api/admin/binaryimages`;
  return mapleResource.get<T>(url);
}

export function postBinaryImage<T>(name: string, formData: FormData) {
  const url = `/api/admin/binaryimage?name=${name}`;
  return mapleResource.post<T>(url, formData);
}

export function deleteBinaryImages<T>(id: string) {
  const url = `/api/admin/binaryimage/${id}`;
  return mapleResource.delete<T>(url);
}
