import { http } from '../api/http';
import { resource } from '../api/resource';
import { AxiosPromise } from 'axios';

// авторизация
export function postLogIn<D>(data: { phone: string; password: string }): AxiosPromise<D> {
  const url = '/api/auth/login/password';
  return http.post<D>(url, data);
}

export function postLogInEmail<D>(data: { emailAddress: string; password: string }): AxiosPromise<D> {
  const url = '/api/auth/login/email';
  return http.post<D>(url, data);
}

// список типов продуктов
export function getProductType<D>(): AxiosPromise<D> {
  const url = '/api/developer/productType';
  return resource.get<D>(url);
}

// список доступной локализаций
export function getLocate<D>(): AxiosPromise<D> {
  const url = '/api/developer/locale';
  return resource.get<D>(url);
}
