import * as T from '../../types';
import * as T_USERS from './types';

export interface StateUser {
  isAuthenticated: boolean;
  isLoadingAuthenticated: boolean;
  errorTextLogIn: string;
  isLoadingLogIn: boolean;
}
const initialState: StateUser = {
  isAuthenticated: false,
  isLoadingAuthenticated: true,
  errorTextLogIn: '',
  isLoadingLogIn: false,
};

export function userReducer(state = initialState, { type, payload }: T.IAction<StateUser>) {
  switch (type) {
    case T_USERS.FETCH_LOGIN: {
      return { ...state, errorTextLogIn: '', isLoadingLogIn: true };
    }
    case T_USERS.FETCH_LOGIN_SUCCESS: {
      return { ...state, errorTextLogIn: '', isLoadingLogIn: false, isAuthenticated: true };
    }
    case T_USERS.FETCH_LOGIN_FAILURE: {
      return { ...state, errorTextLogIn: payload.errorTextLogIn, isLoadingLogIn: false };
    }

    case T_USERS.CHECK_IS_AUTHENTICATED_SUCCESS: {
      return { ...state, isAuthenticated: true, isLoadingAuthenticated: false };
    }
    case T_USERS.CHECK_IS_AUTHENTICATED_FAILURE: {
      return { ...state, isAuthenticated: false, isLoadingAuthenticated: false };
    }

    default:
      return state;
  }
}
